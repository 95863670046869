<template>
  <div class="joinusClass">
    <div class="joinusClass_m1">
      <div class="m">
        <p class="p1">JOIN US</p>
        <p class="p2">加入我们</p>
      </div>
    </div>
    <div class="joinusClass_m2 animated" data-ani="fadeInUp">
      <div class="m23">
        <div class="li" :class="{ on: isActive }" @click="toggleActive" >
          <div class="m">
            <div class="t">
              <div class="v">量化策略研究员</div>
              <div class="v">朝九晚五</div>
              <div class="v">发布时间2021-10-11</div>
              <div class="v1 text-right">
                <i class="icon iconfont icon-jiahao"></i>
              </div>
            </div>
            <div class="ms">
              <div class="mt">
                <div class="v">
                  <p class="p1">岗位要求</p>
                  <p class="p2">
                    1、精通python语言，熟练掌握量化流程；能高效处理各类数据，精通大数据处理技术，并具体实施过相关项目。
                  </p>
                  <p class="p2">2、能阅读文献材料，能复现文献中的框架；</p>
                  <p class="p2">3、具备创新思维能力，对技术有卓越极致追求。</p>
                  <p class="p2">4、1年以上的量化投资模型的建立及策略开发经验者优先，包括但不限于CTA策略、、股票alpha策略、统计套利等策略开发</p>
                  <!-- <p class="p2">
                    5、工作积极主动、责任心强、学习沟通能力强，具有良好的团队协作精神。
                  </p> -->
                </div>
                <div class="v">
                  <p class="p1">工作范围</p>
                  <p class="p2" style="white-space: pre">
                    1、负责国内的股票、期货、期权等金融衍生品量化交易；
                  </p>
                  <p class="p2">
                    2、运用统计软件对金融数据整理，研究相关统计规律
                  </p>
                  <p class="p2">
                    3、配合团队研究开发量化交易策略，完成相应的研究报告。
                  </p>
                </div>
              </div>
              <div class="b">简历投递邮箱：<span>dafantouzi@163.com</span></div>
            </div>
          </div>
        </div>
        <div class="li" :class="{ on: isActive2 }" @click="toggleActive2" >
          <div class="m">
            <div class="t">
              <div class="v">量化交易平台工程师</div>
              <div class="v">朝九晚五</div>
              <div class="v">发布时间2021-10-11</div>
              <div class="v1 text-right">
                <i  class="icon iconfont icon-jiahao"></i>
              </div>
            </div>
            <div class="ms">
              <div class="mt">
                <div class="v">
                  <p class="p1">岗位要求</p>
                  <p class="p2">
                    1、全日制本科以上学历，5年以上IT开发的相关工作经验。
                  </p>
                  <p class="p2">2、1年以上C++开发经验。</p>
                  <p class="p2">3、熟悉掌握数据整理和分析技能，数据库的使用，拥有大数据的建模经验。。</p>
                  <p class="p2">4、熟悉多线程和多进程开发，熟悉微服务开发者有先。</p>
                  <p class="p2">
                    5、熟悉交易平台接口（ctp、迅投、恒生等），1年以上交易系统开发经验者优先
                  </p>
                  <p class="p2">
                    6、拥有金融市场基础知识，能充分理解交易员的思维方式
                  </p>
                  <p class="p2">
                    6、工作积极主动、责任心强、学习沟通能力强，具有良好的团队协作精神。
                  </p>

                </div>
                <div class="v">
                  <p class="p1">工作范围</p>
                  <p class="p2" style="white-space: pre">
                    1、设计、开发、测试及维护量化交易系统.
                  </p>
                  <p class="p2">
                    2、设计并实现高性能计算库，低延迟通讯库，底层基础数据结构包；
                  </p>

                  <p class="p2">
                    3、参与交易系统日常维护，设计网络编程、数据库、进程间通讯、高性能计算等。
                  </p>
                </div>
              </div>
              <div class="b">简历投递邮箱：<span>dafantouzi@163.com</span></div>
            </div>
          </div>
        </div>
        <div class="li" :class="{ on: isActive3 }" @click="toggleActive3">
          <div class="m">
            <div class="t">
              <div class="v">量化交易开发人员</div>
              <div class="v">朝九晚五</div>
              <div class="v">发布时间2021-10-11</div>
              <div class="v1 text-right">
                <i  class="icon iconfont icon-jiahao"></i>
              </div>
            </div>
            <div class="ms">
              <div class="mt">
                <div class="v">
                  <p class="p1">岗位要求</p>
                  <p class="p2">
                    1、计算机、信息、软件相关专业本科学历以上，有项目经验者优先。
                  </p>
                  <p class="p2">2、熟悉C/C++、python编程语言。</p>
                  <p class="p2">3、熟悉常用的数据结构、多线程及多进程编程。</p>
                  <p class="p2">4、对程序开发、金融交易或投资有浓厚兴趣。</p>
                  <p class="p2">
                    5、工作积极主动、责任心强、学习沟通能力强，具有良好的团队协作精神。
                  </p>
                </div>
                <div class="v">
                  <p class="p1">工作范围</p>
                  <p class="p2" style="white-space: pre">
                    1、管理和维护量化交易策略算法.
                  </p>
                  <p class="p2">2、负责每日交易算法调参、仓位管理和监控.</p>
                  <p class="p2">3、参与交易算法开发、优化和更新.</p>

                  <p class="p2">4、市场动态和交易统计报告的生成和管理</p>
                </div>
              </div>
              <div class="b">简历投递邮箱：<span>dafantouzi@163.com</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
        <script>
export default {
  components: {},
  name: "teamNews",
  data() {
    return {
      isActive: false,
      isActive2: false,
      isActive3: false,
      currentTab: "基金学习",
      arrs: ["基金学习", "行业动态", "投资者教育"],

    };
  },
  mounted() {
    this.$nextTick(() => {
     // this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
    },
    toggleActive2() {
      this.isActive2 = !this.isActive2;
    },
    toggleActive3() {
      this.isActive3 = !this.isActive3;
    },
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },
    openNewWindow(route) {
      const routeData = this.$router.resolve(route);
      window.open(routeData.href, "_blank");
    },
    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
      <style lang="scss" scoped>
@media (max-width: 975px) {
  .joinusClass {
    .joinusClass_m1{
      box-sizing: border-box;
      padding: 0px 10px;
    }
    .joinusClass_m2{
      box-sizing: border-box;
      padding: 0px 10px;
    }
  }
}
.joinusClass {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .joinusClass_m2 {
    .m23 {
      overflow: hidden;
      margin: 70px 0px 90px 0px;
      .li {
        overflow: hidden;
        .m {
          overflow: hidden;
          border-top: 1px solid #eeeeee;
          width: 1200px;
          height: auto;
          margin: 0px auto;
          .t {
            height: 76px;
            line-height: 76px;
            display: flex;
            cursor: pointer;
            .v {
              flex: 1;
              font-size: 18px;
              color: #4f4f4f;
            }
            .v1 {
              font-size: 20px;
              color: #e60012;
            }
            .text-right {
              text-align: right;
            }
          }
          .ms {
            overflow: hidden;
            margin-top: 40px;
            display: none;
            .mt {
              overflow: hidden;
              display: flex;
              .v {
                flex: 1;
                margin-right: 30px;
                .p1 {
                  overflow: hidden;
                  font-size: 18px;
                  color: #fff;
                  margin-bottom: 10px;
                  .p2 {
                    overflow: hidden;
                    font-size: 14px;
                    color: #fff;
                    line-height: 30px;
                  }
                }
              }
            }
            .b {
              overflow: hidden;
              font-size: 18px;
              color: #fff;
              margin: 40px 0;
              span {
                color: #e60012;
              }
            }
          }
        }
      }
      .on {
        background: #1b1b1b;
        .m {
          border-color: #1b1b1b;
          .t {
            border-bottom: 1px solid #fff;
            .v {
              color: #fff;
            }
          }
          .ms {
            overflow: hidden;
            margin-top: 40px;
            display: block;
            .mt {
              overflow: hidden;
              display: flex;
              .v {
                flex: 1;
                margin-right: 30px;
                .p1 {
                  overflow: hidden;
                  font-size: 18px;
                  color: #fff;
                  margin-bottom: 10px;
                }
                .p2 {
                  white-space: pre;
                  overflow: hidden;
                  font-size: 14px;
                  color: white;
                  line-height: 20px;
                }
              }
            }
            .b {
              overflow: hidden;
              font-size: 18px;
              color: #fff;
              margin: 40px 0;
              span {
                color: #e60012;
              }
            }
          }
        }
      }
    }
  }
  .joinusClass_m1 {
    width: 100%;
    height: 389px;

    .m {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      box-sizing: border-box;
      padding-top: 180px;
      .p1 {
        overflow: hidden;
        font-size: 34px;
        color: #333;
        display: block;
      }
      .p2 {
        overflow: hidden;
        font-size: 48px;
        display: block;
        color: #333;
      }
    }
  }
}
</style> 