<template>
    <div  style="margin: 20px 0px">
     
   
      
      <div style="box-sizing: border-box; padding: 0px 20px;">
        <el-table
        height="600"
        :data="table_data"
        style="width: 100%">
        <el-table-column
          prop="dates"
          label="净值日期"
         >
        </el-table-column>
        <el-table-column
          prop="unit_net_worth"
          label="净值单位"
        >
        </el-table-column>
        <el-table-column
          prop="CNt"
          label="累计净值(投资再分红)"
         >
        </el-table-column>
        <el-table-column
          prop="cumulative_net_worth"
          label="累计净值(投资不分红)"
         >
        </el-table-column>
        <el-table-column
          prop="weekly_change"
          label="净值变动"
         >
        </el-table-column>
        <!-- <el-table-column
          prop="text"
          label="净值来源"
         >
        </el-table-column> -->
      
      </el-table>
      </div>
    </div>
  </template>
    <script>
  export default {
    components: {},
    props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
    },

    
  },
  watch: {
    chartData: {
      handler: function (newval, oldval) {
          this.table_data = newval
      },
    },
  },
    data() {
      return {
        
        table_data: [

  
          ]
      };
    },
    mounted() {
    
    },
    destroyed() {},
    methods: {
      tabActive(i) {
        this.num = i;
      },
  
      
      
    },
  };
  </script>
     <style >
  .text-white {
    background-color: #e1322d1a;
    font-weight: 700;
    color: red;
  }
  </style> 