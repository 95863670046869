import Cookies from 'js-cookie'
const TokenKey = 'vue_admin_template_token'
const is_activeKEY = 'vue_admin_template_is_activeKEY'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


export function get_is_active() {
  return  Cookies.get(is_activeKEY)
}

export function set_is_active(is_active) {
  return Cookies.set(is_activeKEY, is_active)
}

export function remove_is_active() {
  return Cookies.remove(is_activeKEY)
}
