<template>
  <div class="teamNews">
    <div class="teamNews_m1">
      <div class="m flex">
        <p>认清自己，才是投资成功的决定性因素</p>
      </div>
    </div>
    <div class="teamNews_m2">
      <div class="ts">
        <a
          v-for="(item, index) in arrs"
          :key="index"
          :class="{ on: currentTab === item }"
          @click="currentTab = item"
          href="javascript:void(0)"
          >{{ item }}</a
        >
      </div>
    </div>
    <div class="teamNews_m3">
      <div class="m">
        <div class="ts animated fadeInUp" data-animation="fadeInUp"></div>
        <ul class="u">
          <template v-for="(item, index) in list_Data">
            <li
              :key="index"
              v-if="item.news_type == currentTab"
              data-animation="fadeInUp"
              class="animated fadeInUp"
              @click="openNewWindow(item)"
            >
              <a href="javascript:void(0)">
                <div class="im">
                  <!-- <img :src='http://dafantouzi.com/media/'+item.path+'' /> -->
                  <img :src="`http://dafantouzi.com/media/`+item.path" alt="">
                </div>
                <div class="des">
                  <p class="p1">{{ item.update_date}}</p>
                  <p class="p2">
                    {{ item.title}}
                  </p>
                </div>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
      <script>
export default {
  components: {},
  name: "teamNews",
  data() {
    return {
      currentTab: '基金学习',
      arrs: ["基金学习", "行业动态", "投资者教育"],
      // arrs: [
      //   {
      //     type: 0,
      //     text: "基金学习",
      //   },
      //   {
      //     type: 1,
      //     text: "行业动态",
      //   },
      //   {
      //     type: 2,
      //     text: "投资者教育",
      //   },
      // ],
      list_Data: [],
    };
  },
  mounted() {
    this.listAjax();
    this.$nextTick(() => {
      // this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    listAjax() {
      this.$store
        .dispatch("user/get_news", {})
        .then((e) => {
          this.list_Data = e;
        })
        .catch(() => {});
    },
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },
    openNewWindow(el) {
      // const routeData = this.$router.resolve(route);
      // window.open(routeData.href, "_blank");

      const routeData = this.$router.resolve({
        path: "/newclass",
        query: el,
      });
      window.open(routeData.href, "_blank");



    },
    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
    <style lang="scss" scoped>
@media (max-width: 975px) {
  .teamNews {
    .teamNews_m2 {
      .ts {
        width: 100% !important;
      }
    }
    .teamNews_m3 {
      .m {
        width: 100% !important;
        ul {
          padding: 0;
          li {
            width: 420px;
            margin: 30px auto !important;
            float: none;
          }
        }
      }
    }
  }
}
.teamNews {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .teamNews_m1 {
    width: 100%;
    height: 589px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../assets/i13.jpg);
    .m {
      width: 1200px;
      height: 100%;
      display: flex;
      margin: 0 auto;
      p {
        align-self: center;
        font-size: 24px;
        color: #000;
        line-height: 44px;
        padding-top: 40px;
      }
    }
  }
  .teamNews_m2 {
    .ts {
      width: 1200px;
      height: auto;

      overflow: hidden;
      text-align: center;
      margin: 50px auto;
      a {
        display: inline-block;
        font-size: 24px;
        font-weight: bold;
        color: #999;
        padding: 10px 30px;
        margin: 0px 50px;
      }
      .on {
        color: #000 !important;
      }
    }
  }
  .teamNews_m3 {
    overflow: hidden;
    .m {
      width: 1200px;
      height: auto;
      margin: 0px auto;
    }
    .u {
      width: 100%;
      overflow: hidden;
      margin-top: 50px;
      li {
        float: left;
        margin-left: 10px;
        width: 570px;
        background: #fff;
        box-shadow: 0px 0px 10px #ddd;
        margin-top: 10px;
        margin-bottom: 40px;
        a {
          display: block;
          overflow: hidden;
          transition: 0.5s;
          .im {
            height: 260px;
            overflow: hidden;
            img {
              width: 100%;
              overflow: hidden;
              transition: 0.5s;
            }
          }
          .des {
            padding: 30px 35px 30px;
            .p1 {
              overflow: hidden;
              font-size: 16px;
              color: #666;
            }
            .p2 {
              font-size: 18px;
              color: #333;
              line-height: 30px;
              height: 60px;
              overflow: hidden;
              margin-top: 10px;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
            }
          }
        }
        &:hover .im img {
          transform: scale(1.1);
        }
      }
    }
  }
}
</style> 