var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joinus"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"joinus_m3"},[_c('ul',{staticClass:"m20"},[_c('li',{staticClass:"li animated",staticStyle:{"background":"#fafafa"},attrs:{"data-ani":"fadeInUp"},on:{"click":_vm.pagehref}},[_vm._m(2)]),_c('li',{staticClass:"li animated",staticStyle:{"background":"white"},attrs:{"data-ani":"fadeInUp"},on:{"click":_vm.pagehref}},[_vm._m(3)]),_c('li',{staticClass:"li animated",staticStyle:{"background":"#fafafa"},attrs:{"data-ani":"fadeInUp"},on:{"click":_vm.pagehref}},[_vm._m(4)])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joinus_m1"},[_c('div',{staticClass:"m flex"},[_c('p',{staticClass:"text-right flex1",staticStyle:{"color":"#fff"}},[_vm._v(" 如果你得到了坐上火箭的机会，别管是什么位置，上去就行。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joinus_m2 animated",attrs:{"data-ani":"fadeInUp"}},[_c('div',{staticClass:"m"},[_c('div',{staticClass:"t"},[_vm._v("和有趣的人一起工作")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m bg1"},[_c('div',{staticClass:"ms"},[_c('p',{staticClass:"p1"},[_vm._v("量化策略研究员")]),_c('ul',[_c('li',[_c('span',[_vm._v("·")]),_vm._v("python语言")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v("创新思维")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v("复现文献中的框架")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v("1年+量化投资模型的建立")])]),_c('p',{staticClass:"p2"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("查看更多 "),_c('i',{staticClass:"icon iconfont icon-jiantou_xiangyou_o"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m bg2"},[_c('div',{staticClass:"ms"},[_c('p',{staticClass:"p1"},[_vm._v("量化交易平台工程师")]),_c('ul',[_c('li',[_c('span',[_vm._v("·")]),_vm._v("本科以上学历")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v(" 5年+IT开发的相关工作经验")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v(" 1年+C++开发经验")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v(" 数据整理和分析技能")])]),_c('p',{staticClass:"p2"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("查看更多 "),_c('i',{staticClass:"icon iconfont icon-jiantou_xiangyou_o"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m bg3"},[_c('div',{staticClass:"ms"},[_c('p',{staticClass:"p1"},[_vm._v("量化交易开发人员")]),_c('ul',[_c('li',[_c('span',[_vm._v("·")]),_vm._v("相关专业本科以上学历")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v(" 熟悉C/C++、python")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v(" 对金融投资有浓厚兴趣")]),_c('li',[_c('span',[_vm._v("·")]),_vm._v(" 工作积极主动")])]),_c('p',{staticClass:"p2"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("查看更多 "),_c('i',{staticClass:"icon iconfont icon-jiantou_xiangyou_o"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"joinus_m4"},[_c('div',{staticClass:"m"},[_vm._v(" 在这里找到适合你的职位，简历投递邮箱："),_c('span',[_vm._v("dafantouzi@163.com"),_c('span')])])])
}]

export { render, staticRenderFns }