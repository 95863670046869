<template>
  <div class="joinus">
    <div class="joinus_m1">
      <div class="m flex">
        <p class="text-right flex1" style="color: #fff">
          如果你得到了坐上火箭的机会，别管是什么位置，上去就行。
        </p>
      </div>
    </div>
    <div class="joinus_m2 animated" data-ani="fadeInUp">
      <div class="m">
        <div class="t">和有趣的人一起工作</div>
      </div>
    </div>
    <!-- <img src="../assets/9a0ca741f5ce83ca3056bb9312547b3d.png" alt=""> -->
    <div class="joinus_m3">
      <ul class="m20">
        <li
          class="li animated"
          @click="pagehref"
          data-ani="fadeInUp"
          style="background: #fafafa"
        >
          <div class="m bg1" style="">
            <div class="ms">
              <p class="p1">量化策略研究员</p>
              <ul>
                <li><span>·</span>python语言</li>
                <li><span>·</span>创新思维</li>
                <li><span>·</span>复现文献中的框架</li>
                <li><span>·</span>1年+量化投资模型的建立</li>
              </ul>
              <p class="p2">
                <a href="javascript:void(0)"
                  >查看更多 <i class="icon iconfont icon-jiantou_xiangyou_o"></i
                ></a>
              </p>
            </div>
          </div>
        </li>
        <li
          class="li animated"
          @click="pagehref"
          data-ani="fadeInUp"
          style="background: white"
        >
          <div class="m bg2">
            <div class="ms">
              <p class="p1">量化交易平台工程师</p>
              <ul>
                <li><span>·</span>本科以上学历</li>
                <li><span>·</span> 5年+IT开发的相关工作经验</li>
                <li><span>·</span> 1年+C++开发经验</li>
                <li><span>·</span> 数据整理和分析技能</li>
              </ul>
              <p class="p2">
                <a href="javascript:void(0)"
                  >查看更多 <i class="icon iconfont icon-jiantou_xiangyou_o"></i
                ></a>
              </p>
            </div>
          </div>
        </li>
        <li
          class="li animated"
          @click="pagehref"
          data-ani="fadeInUp"
          style="background: #fafafa"
        >
          <div class="m bg3">
            <div class="ms">
              <p class="p1">量化交易开发人员</p>
              <ul>
                <li><span>·</span>相关专业本科以上学历</li>
                <li><span>·</span> 熟悉C/C++、python</li>
                <li><span>·</span> 对金融投资有浓厚兴趣</li>
                <li><span>·</span> 工作积极主动</li>
              </ul>
              <p class="p2">
                <a href="javascript:void(0)"
                  >查看更多 <i class="icon iconfont icon-jiantou_xiangyou_o"></i
                ></a>
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="joinus_m4">
      <div class="m">
        在这里找到适合你的职位，简历投递邮箱：<span
          >dafantouzi@163.com<span> </span
        ></span>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      //  this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },
    pagehref() {
      const routeData = this.$router.resolve({
        path: "/joinusClass",
        // query: el,
      });
      window.open(routeData.href, "_blank");
    },
    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 975px) {
  .joinus {
    .joinus_m1 {
      .m {
        width: 100% !important;
      }
    }
    .joinus_m2 {
      .m {
        width: 100% !important;
        padding-top: 30px !important;
      }
    }
    .joinus_m3 {
      ul {
        li {
       
          .m {
            box-sizing: border-box;
            padding-left: 10px;
            width: 100% !important;
            .ms {
              ul {
                li {
                  color: black !important;
                }
              }
            }
          }
          .bg2{
            box-sizing: border-box;
            padding-left: 10px;
            .ms{
              margin-left:  0 !important;
              .p1{
                color: white !important;
              }
              .p2{
                a{
                  color: white !important;
                }
              }
              ul {
                li {
                  color: white !important;
                }
              }
            }
          }
        }
      }
    }
    .joinus_m4 {
      .m {
        width: 100% !important;
      }
    }
  }
}
.joinus {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .joinus_m4 {
    overflow: hidden;
    background: #fff;
    padding: 100px 0px;
    .m {
      overflow: hidden;
      text-align: center;
      font-size: 24px;
      color: #333;
      width: 1200px;
      height: auto;
      margin: 0px auto;
      span {
        color: #e60012;
      }
    }
  }
  .joinus_m3 {
    width: 100%;
    overflow: hidden;
    ul {
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;
      li {
        width: 100%;
        overflow: hidden;
        padding: 90px 0px;
        .bg1 {
          background-image: url(../assets/f6b63cc311cd841f091e79296af792bb.png);
        }
        .bg2 {
          background-position: left center !important;
          background-image: url(../assets/9a0ca741f5ce83ca3056bb9312547b3d.png);
          .ms {
            margin-left: 690px;
          }
        }
        .bg3 {
          background-image: url(../assets/3d9ae39d36c5a8968e753ec9ed0d0298.png);
        }
        .m {
          background-repeat: no-repeat;
          background-position: right center;
          display: flex;
          height: 370px;
          width: 1200px;

          margin: 0px auto;

          .ms {
            align-self: center;
            .p1 {
              font-size: 36px;
              font-weight: bold;
              color: #333;
            }
            .p2 {
              overflow: hidden;
              font-size: 18px;
              color: #fff;
              margin-top: 50px;
              a {
                color: #333;
              }
            }
            > ul {
              width: 560px;
              overflow: hidden;
              margin-top: 20px;
              > li {
                width: 50%;
                float: left;
                line-height: 42px;
                font-size: 18px;
                color: #999;
                height: 42px;
                padding: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                span {
                  font-size: 24px;
                  font-weight: bold;
                  padding-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.joinus_m2 {
  background: #fafafa;

  .m {
    width: 1200px;
    height: auto;
    padding-top: 100px;
    margin: 0px auto;
  }
  .t {
    text-align: center;
    font-size: 40px;
  }
}
.joinus_m1 {
  background-image: url(../assets/i20.jpg);
  width: 100%;
  height: 589px;
  background-repeat: no-repeat;
  background-position: center center;
  .m {
    width: 1200px;
    height: auto;
    margin: 0px auto;
    text-align: right;
    p {
      align-self: center;
      font-size: 24px;
      color: #000;
      margin: 0;
      line-height: 589px;
    }
  }
}
</style> 