const getters = {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    group_type: state => state.user.group_type,
    name: state => state.user.name,
    is_active: state => state.user.is_active,
    roleCode: state => state.user.roleCode,
    addRouters: state => state.user.addRouters,
  }
  export default getters
  