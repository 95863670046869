import Vue from 'vue'
import Router from 'vue-router'
const Hender = resolve => require(['@/components/Hender'], resolve)
const HenderWhite = resolve => require(['@/components/HeaderWhite'], resolve)
const Footer = resolve => require(['@/components/Footer'], resolve)
import HelloWorld from '@/components/HelloWorld'
import indexs from '@/pages/index'
import abouts from '@/pages/about'
import contacts from '@/pages/contact'
import goodsteam from '@/pages/goodsteam'
import teaching from '@/pages/teaching'
import joinus from '@/pages/joinus'
import bonus from '@/pages/bonus'
import newclass from '@/pages/newclass'
import news from '@/pages/news'
import team from '@/pages/team'
import company from '@/pages/company'
import teamNews from '@/pages/teamNews'
import joinusClass from '@/pages/joinusClass'
import charts from '@/pages/charts'

Vue.use(Router)

const router = new Router(
  {
   // mode: 'history',
    routes: [{
        path: '/',
        components: {
          hender: Hender,
          footer: Footer,
          default: indexs
        }
      },
      {
        path: '/home',
        name: 'home',
        components: {
          hender: Hender,
          footer: Footer,
          default: indexs
        }
      },
      {
        path: '/charts',
        name: 'charts',
        components: {
          hender: HenderWhite,

          default: charts
        },
      },
      {
        path: '/newclass',
        name: 'newclass',
        components: {
          hender: Hender,
          footer: Footer,
          default: newclass
        }
      },
      {
        path: '/news',
        name: 'news',
        components: {
          hender: Hender,
          footer: Footer,
          default: news
        }
      },

      {
        path: '/joinusClass',
        name: 'joinusClass',
        components: {
          hender: Hender,
          footer: Footer,
          default: joinusClass
        }
      },
      {
        path: '/teamNews',
        name: 'teamNews',
        components: {
          hender: Hender,
          footer: Footer,
          default: teamNews
        }
      },
      {
        path: '/team',
        name: 'team',
        components: {
          hender: Hender,
          footer: Footer,
          default: team
        }
      },
      {
        path: '/company',
        name: 'company',
        components: {
          hender: Hender,
          footer: Footer,
          default: company
        }
      },

      {
        path: '/goodsteam',
        name: 'goodsteam',
        components: {
          hender: Hender,
          footer: Footer,
          default: goodsteam
        }
      },
      {
        path: '/about',
        name: 'about',
        components: {
          hender: Hender,
          footer: Footer,
          default: abouts
        }
      },
      {
        path: '/bonus',
        name: 'bonus',
        components: {
          hender: Hender,
          footer: Footer,
          default: bonus
        }
      },
      {
        path: '/contacts',
        name: 'contacts',
        components: {
          hender: Hender,
          footer: Footer,
          default: contacts
        }
      },

      {
        path: '/teaching',
        name: 'teaching',
        components: {
          hender: Hender,
          footer: Footer,
          default: teaching
        }
      },
      {
        path: '/joinus',
        name: 'joinus',
        components: {
          hender: Hender,
          footer: Footer,
          default: joinus
        }
      }

    ]
  })

router.afterEach((to, from) => {
  // 在每次路由跳转后滚动到页面底部
  window.scrollTo(0, 0);
});
export default router;