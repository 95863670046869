<template>
  <div>
    <div style="margin: 20px 0px">
      <ul style="width: 100%; padding: 0; margin: 0; text-align: left">
        <!-- v-for相当于 JS 中的循环遍历数组，其中 item 表示数组的每一项对象（可自定义名称），index表示下标（可自定义名称） -->
        <!-- :key 是 v-bind:key 的简写，此处是为了避免数据重复渲染 -->
        <!-- 该标签的类名采用三目运算符进行判断，其中 :class 表示动态绑定数据 -->
        <!-- @click="事件名称" 表示点击则出发对应事件 -->
        <!-- {{}} 是插值表达式的写法，可以直接引入 vue 实例中的属性 -->
        <li
          v-for="(item, index) in arr"
          :key="item.id"
          style="
            list-style: none;
            font-size: 13px;
            padding: 3px 10px;
            cursor: pointer;
            display: inline-block;
          "
          :class="[
            'list-group-item',
            num === index ? 'bg-primary text-white' : '',
          ]"
          @click="tabActive(index)"
        >
          {{ item.text }}
        </li>
      </ul>
    </div>
    <el-row>
      <el-col
        :span="24"
        v-loading="myChartm1_loading"
        element-loading-text="加载中..."
      >
        <div
          id="myChart"
          style="width: 100%; height: 400px; margin-bottom: 40px"
        ></div>
      </el-col>
      <el-col
        :span="24"
        v-loading="myChartm2_loading"
        element-loading-text="加载中..."
      >
        <div
          id="myChart2"
          style="width: 100%; height: 400px; margin-bottom: 40px"
        ></div>
      </el-col>
    </el-row>

    <div
      style="
        color: #0006;
        font-size: 12px;
        text-align: left;
        padding: 20px 10px;
      "
    >
      风险提示：基金管理人及其所管理的基金产品过往业绩不预示其未来表现，我司所展示的数据包括基础数据和衍生数据（经基础数据加工处理），基础数据来源于基金管理人/托管人/估值核算机构/聚源数据，我司仅对上述数据进行客观展示或披露。虽我司尽力保证数据的全面性、及时性和准确性，但仍无法完全避免因上述主体数据发送延误、错漏等特殊原因导致产品基础、衍生数据不准确等情形。我司慎重提醒投资者须以中国证监会资本市场电子化信息披露平台、基金管理人官方网站及其委托的基金估值核算机构发布的内容为准，请投资者认真阅读产品信息，查看历史净值等各项数据的波动及来源情况，审慎独立地作出投资决策。
    </div>
    <div style="box-sizing: border-box; padding: 0px 20px">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="本基金"> </el-table-column>
        <el-table-column prop="date1" label="成立以来"> </el-table-column>
        <el-table-column prop="date2" label="最近一月"> </el-table-column>
        <el-table-column prop="date3" label="最近三月"> </el-table-column>
        <el-table-column prop="date4" label="最近半年"> </el-table-column>
        <el-table-column prop="date5" label="今年以来"> </el-table-column>
        <el-table-column prop="date6" label="最近一年"> </el-table-column>
        <el-table-column prop="date7" label="最近两年"> </el-table-column>
        <el-table-column prop="date8" label="最近三年"> </el-table-column>
        <el-table-column prop="date9" label="最近五年"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>
  <script>
let myChart2 = null;
let myChart = null;
import moment from "moment";
export default {
  components: {},
  props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
    },

    fund_data: {
      type: Object,
      default: "",
    },
  },
  watch: {
    chartData: {
      handler: function (newval, oldval) {
        this.$nextTick(() => {
          this.drawLine();
          this.drawLine2();
        });
      },
    },
  },
  data() {
    return {
      myChartm1_loading: false,
      myChartm2_loading: false,
      arr: [
        { text: "成立来", id: 1 },
        { text: "运作以来", id: 2 },
        { text: "近一月", id: 3 },
        { text: "近三月", id: 4 },
        { text: "近半年", id: 5 },
        { text: "近一年", id: 6 },
        { text: "今年来", id: 7 },
      ],
      num: 2,
      tableData: [
        {
          name: "涨幅跌",
          id: 1,
          date1: "52.06%",
          date2: "52.16%",
          date3: "3.06%",
          date4: "22.06%",
          date5: "66.06%",
          date6: "52.06%",
        },
        {
          name: "回撤",
          id: 1,
          date1: "52.06%",
          date2: "52.16%",
          date3: "3.06%",
          date4: "22.06%",
          date5: "66.06%",
          date6: "52.06%",
        },
      ],
    };
  },
  created() {},
  mounted() {
    myChart = this.$echarts.init(document.getElementById("myChart"));
    myChart2 = this.$echarts.init(document.getElementById("myChart2"));
    this.drawLine();
    this.drawLine2();

    if (myChart || myChart2) {
      window.addEventListener("resize", function () {
        myChart.resize();
        myChart2.resize();
      });
    }
    let old = this.fund_data;

    //  let obj1 = {
    //       name: "涨幅跌",
    //       id: 1,
    //       fit_CNt: "52.06%",
    //       past_1_monthfit_CNt: "52.16%",
    //       past_3_monthfit_CNt: "3.06%",
    //       past_6_monthfit_CNt: "22.06%",
    //       this_yearfit_CNt: "66.06%",
    //       past_yearfit_CNt:"52.06%",
    //       past_2_yearfit_CNt:'',
    //       past_3_yearfit_CNt:'',
    //       past_5_yearfit_CNt:'',
    //  }
    //  let obj2 = {
    //       name: "回撤",
    //       id: 2,
    //       cumulative_maximum_drawdown_CNt: "52.06%",
    //       past_1_month_maximum_drawdown_CNt: "52.16%",
    //       past_3_month_maximum_drawdown_CNt: "3.06%",
    //       past_6_month_maximum_drawdown_CNt: "22.06%",
    //       this_year_maximum_drawdown_CNt: "66.06%",
    //       past_year_maximum_drawdown_CNt: "52.06%",
    //       past_2_year_maximum_drawdown_CNt:'',
    //       past_3_year_maximum_drawdown_CNt:'',
    //       past_5_year_maximum_drawdown_CNt:'',
    //  }

    let ars = [
      {
        name: "涨幅跌",
        id: 1,
        date1: old.fit_CNt,
        date2: old.past_1_monthfit_CNt,
        date3: old.past_3_monthfit_CNt,
        date4: old.past_6_monthfit_CNt,
        date5: old.this_yearfit_CNt,
        date6: old.past_yearfit_CNt,
        date7: old.past_2_yearfit_CNt,
        date8: old.past_3_yearfit_CNt,
        date9: old.past_5_yearfit_CNt,
      },
      {
        name: "回撤",
        id: 2,
        date1: old.cumulative_maximum_drawdown_CNt,
        date2: old.past_1_month_maximum_drawdown_CNt,
        date3: old.past_3_month_maximum_drawdown_CNt,
        date4: old.past_6_month_maximum_drawdown_CNt,
        date5: old.this_year_maximum_drawdown_CNt,
        date6: old.past_year_maximum_drawdown_CNt,
        date7: old.past_2_year_maximum_drawdown_CNt,
        date8: old.past_3_year_maximum_drawdown_CNt,
        date9: old.past_5_year_maximum_drawdown_CNt,
      },
    ];

    this.tableData = ars;
  },
  destroyed() {},
  methods: {
    tabActive(i) {
      this.num = i;
      this.myChartm1_loading = true;
      this.myChartm2_loading = true;
      if (i == 0) {
        this.$emit("tabClick-Date", {
          start_date: "",
          end_date: "",
        });
      } else if (i == 1) {
        this.$emit("tabClick-Date", {
          start_date: "",
          end_date: "",
        });
      } else if (i == 2) {
        this.$emit("tabClick-Date", {
          start_date: this.getLastOneMonthRange()[0].format("YYYY-MM-DD"),
          end_date: this.getLastOneMonthRange()[1].format("YYYY-MM-DD"),
        });
      } else if (i == 3) {
        this.$emit("tabClick-Date", {
          start_date: this.getLastThreeMonthRange()[0].format("YYYY-MM-DD"),
          end_date: this.getLastThreeMonthRange()[1].format("YYYY-MM-DD"),
        });
      } else if (i == 4) {
        this.$emit("tabClick-Date", {
          start_date: this.getLastsexMonthRange()[0].format("YYYY-MM-DD"),
          end_date: this.getLastsexMonthRange()[1].format("YYYY-MM-DD"),
        });
      } else if (i == 5) {
        this.$emit("tabClick-Date", {
          start_date: this.getLastMonthRange()[0].format("YYYY-MM-DD"),
          end_date: this.getLastMonthRange()[1].format("YYYY-MM-DD"),
        });
      } else if (i == 6) {
        this.$emit("tabClick-Date", {
          start_date: this.getLastyear()[0].format("YYYY-MM-DD"),
          end_date: this.getLastyear()[1].format("YYYY-MM-DD"),
        });
      }
      // if (i == 4) {

      // }getLastyear
      //
    },
    someMethod(i) {},
    getLastOneMonthRange() {
      const start = moment().startOf("month").subtract(1, "months"); // 上个月的同一天，作为开始日期
      const end = moment().endOf("month"); // 当前月的最后一天，作为结束日期
      return [start, end];
    },

    // 获取近三月时间范围
    getLastThreeMonthRange() {
      const start = moment().startOf("month").subtract(3, "months"); // 三个月前的同一天，作为开始日期
      const end = moment().endOf("month"); // 当前月的最后一天，作为结束日期
      return [start, end];
    },
    // 获取近六月时间范围
    getLastsexMonthRange() {
      const start = moment().startOf("month").subtract(6, "months"); // 三个月前的同一天，作为开始日期
      const end = moment().endOf("month"); // 当前月的最后一天，作为结束日期
      return [start, end];
    },
    // 获取近一年时间范围
    getLastMonthRange() {
      const start = moment().startOf("month").subtract(12, "months"); // 三个月前的同一天，作为开始日期
      const end = moment().endOf("month"); // 当前月的最后一天，作为结束日期
      return [start, end];
    },
    getLastyear() {
      const start = moment().startOf("year"); // 三个月前的同一天，作为开始日期
      const end = moment(); // 当前月的最后一天，作为结束日期
      return [start, end];
    },

    drawLine() {
      // 基于准备好的dom，初始化echarts实例

      // 绘制图表
      //weekly_drawdown
      let x = [];
      let y = [];
      let arrs = this.chartData;
      for (let i = 0; i < arrs.length; i++) {
        x.push(arrs[i].dates);
        y.push(arrs[i].fit_CNt);
      }
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: function (val) {
            let string = val[0].name + "<br>";
            val.forEach((v) => {
              string += `<div style='display: flex;align-items: center;justify-content: space-between;'><span>${v.marker}${v.seriesName}</span>&nbsp; &nbsp; <span style='font-weight: 700;'>${v.value}%</span></div>`;
            });
            return string;
          },
        },
        legend: {
          // left: 10,
          // top: 0,
          data: [this.fund_data.fund_name],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: x,
          },
        ],
        yAxis: [
          {
            type: "value",
            min: Math.min.apply(null, y),
            axisLabel: {
              formatter: "{value}" + "%",
            },
          },
        ],
        series: [
          {
            name: this.fund_data.fund_name,
            type: "line",
            stack: "Total",
            symbolSize: 2,
            itemStyle: {
              normal: {
                color: "#E1322C", //图例颜色
                // borderWidth: 2,
                // borderColor: "#f15441",
                // lineStyle: { color: "#f15441", width: 1 }
              },
            },

            areaStyle: {
              // color: "rgba(49, 250, 123, 1)",
              normal: {
                // 渐变填充色（线条下半部分）
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#FAE6E5" },
                  { offset: 1, color: "#FAE6E5" },
                ]),
              },

              origin: "rgba",
            },
            emphasis: {
              focus: "series",
            },
            data: y,
          },
          // {
          //   name: "沪深300",
          //   type: "line",

          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [220, 182, 191, 234, 290, 330, 310],
          // },
          // {
          //   name: "超额收益(几何)",
          //   type: "line",

          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [150, 232, 201, 154, 190, 330, 410],
          // },
        ],
      });
      myChart.resize();
      this.myChartm1_loading = false;

      //
    },
    drawLine2() {
      // 基于准备好的dom，初始化echarts实例

      // 绘制图表
      //

      let x2 = [];
      let y2 = [];
      let arrs = this.chartData;
      for (let i = 0; i < arrs.length; i++) {
        x2.push(arrs[i].dates);
        y2.push(arrs[i].weekly_drawdown);
      }

      myChart2.setOption({
        title: {
          text: "动态回撤",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: function (val) {
            let string = val[0].name + "<br>";
            val.forEach((v) => {
              string += `<div style='display: flex;align-items: center;justify-content: space-between;'><span>${v.marker}${v.seriesName}</span>&nbsp; &nbsp; <span style='font-weight: 700;'>${v.value}%</span></div>`;
            });
            return string;
          },
        },
        legend: {
          data: [this.fund_data.fund_name],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "0%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: x2,
          },
        ],
        yAxis: [
          // {
          //   type: "value",
          //   scale: true
          // },
          {
            nameLocation: "start",
            alignTicks: true,
            type: "value",
            inverse: true,
            axisLabel: {
              formatter: "{value}" + "%",
            },
          },
        ],
        series: [
          {
            name: this.fund_data.fund_name,
            type: "line",
            stack: "Total",
            itemStyle: {
              normal: {
                color: "#E1322C", //图例颜色
                // borderWidth: 2,
                // borderColor: "#f15441",
                // lineStyle: { color: "#f15441", width: 1 }
              },
            },

            areaStyle: {
              // color: "rgba(49, 250, 123, 1)",
              normal: {
                // 渐变填充色（线条下半部分）
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#FAE6E5" },
                  { offset: 1, color: "#FAE6E5" },
                ]),
              },

              origin: "rgba",
            },
            emphasis: {
              focus: "series",
            },
            data: y2,
          },
          // {
          //   name: "沪深300",
          //   type: "line",

          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [220, 182, 191, 234, 290, 330, 310],
          // },
          // {
          //   name: "超额收益(几何)",
          //   type: "line",

          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [150, 232, 201, 154, 190, 330, 410],
          // },
        ],
      });
      myChart2.resize();
      this.myChartm2_loading = false;
    },
  },
};
</script>
   <style >
.text-white {
  background-color: #e1322d1a;
  font-weight: 700;
  color: red;
}
</style> 