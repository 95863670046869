<template>
  <div class="newclass">
    <div class="newclass_m1">
      <div class="m flex">
        <p>认清自己，才是投资成功的决定性因素</p>
      </div>
    </div>
    <div class="newclass_m2 animated" data-ani="fadeInUp">
      <div class="m">
        <div class="ms">
          <div class="ts">
            <h1>{{ pageData.title }}</h1>
            <p>{{ pageData.update_date }}</p>
          </div>
          <div class="content">
            <div v-html="texts"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  components: {},
  name: "newclass",
  data() {
    return {
      pageData: {},
      texts: "",
    };
  },
  created() {
    this.pageData = this.$route.query;
    let str = this.$route.query.text.replace(/\n/g, "</br>");
    this.texts = str;
  },
  mounted() {
    this.$nextTick(() => {
      //this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },

    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
@media (max-width: 975px) {
  .newclass {
  }
}
.newclass {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .newclass_m1 {
    background-image: url(../assets/i19.jpg);
    width: 100%;
    height: 589px;
    background-repeat: no-repeat;
    background-position: center center;
    .m {
      width: 1200px;
      height: 100%;
      display: flex;
      margin: 0px auto;
      p {
        align-self: center;
        font-size: 24px;
        color: #000;
        line-height: 44px;
        padding-top: 40px;
      }
    }
  }
  .newclass_m2 {
    .m {
      width: 1200px;
      height: 100%;
      background: #fff;
      position: relative;
      margin: 0px auto;
      .ms {
        width: auto;
        padding: 80px;
        .ts {
          overflow: hidden;
          border-bottom: 1px solid #eeeeee;
          padding-bottom: 20px;
        }
        .content {
          margin-top: 40px;
          width: 100%;
          overflow: hidden;
          font-size: 15px;
          color: #666;
          line-height: 30px;
        }
      }
      .s {
        display: inline-block;
        width: auto;
        line-height: 50px;
        font-size: 18px;
        color: #999;
        position: absolute;
        left: -150px;
        bottom: 80px;
      }
      .x {
        display: inline-block;
        width: auto;
        line-height: 50px;
        font-size: 18px;
        color: #999;
        position: absolute;
        right: -150px;
        bottom: 80px;
      }
    }
  }
}
</style> 