import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.config.productionTip = false
Vue.use(ElementUI);
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
import store from './store'

import VueBus from 'vue-bus';
import router from './router'
Vue.use(VueRouter);

Vue.use(VueBus);

// Import BootstrapVue
import {
  BootstrapVue,
  BootstrapVueIcons
} from 'bootstrap-vue'
import '@/assets/fonts/iconfont'


// Import Bootstrap and BootstrapVue CSS files ( ensure to follow the order)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import "./assets/fonts/iconfont.css"
// 引入 vue-awesome-swiper

import removeAria from "./utils/removeAria";

Vue.directive("removeAria", removeAria);

// import "amfe-flexible/index"
// Vue.directive('scroll-to', {
//   inserted: function (el, binding) {
//     console.log(el)
//     console.log(binding)
//     const target = binding.value && binding.value.target; // 获取目标元素
//     if (!target) {
//       console.error('Target element must be provided with the `target` binding.');
//       return;
//     }

//     window.addEventListener('scroll', function () {
//       const rect =  target.getBoundingClientRect();
//       if (rect.top >= 0 && rect.left >= 0 &&
//           rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//           rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
//         // 当元素滚动到视口中时执行
//         binding.value.callback();
//         window.removeEventListener('scroll', this); // 移除监听器以避免重复触发
//       }
//     });
//   }
// });

import './utils/rem.js'
new Vue({
  router,
  store,
  render: h => h(App),

}).$mount('#app')