<template>
  <div class="contacts">
    <div class="contacts_m1">
      <div class="v1">
        <p class="v1-t">关注大凡，投资基金产品</p>
        <div class="v1-m flex">
          <div class="l flex1">
            <p class="p1">联系大凡</p>
            <p class="p2">电话：<span>023-67848897 / 67849055</span></p>
            <p class="p3">
              地址：<a
                href="http://api.map.baidu.com/marker?location=29.605717,106.535843&amp;title=重庆大凡私募证券投资基金管理有限公司&amp;content=资产管理,投资公司,资产管理公司&amp;output=html"
                target="_blank"
                style="color: #808080"
                title="点击查看详情"
                >重庆市渝北区龙溪街道新溉大道99号重庆基金小镇7栋8楼</a
              >
            </p>
            <p class="p4">邮箱：dafantouzi@163.com</p>
            <div class="p5">
              关注我们：
              <div class="i">
                <a href="javascript:;"
                  ><i class="icon iconfont icon-xinlang"></i
                ></a>
              </div>
              <div class="i">
                <a href="javascript:;"><i class="icon iconfont icon-qq"></i></a>
              </div>
              <div class="i">
                <a href="javascript:;"
                  ><i class="icon iconfont icon-weixin"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="r">
            <p class="p1">微信扫码关注</p>
            <p class="p2"><img src="../assets/i5.jpg" /></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  components: {},
  name: "contacts",
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      //  this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      // addEventListener("scroll", this.handleScroll);
      // addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },

    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 975px) {
  .contacts {
    .contacts_m1 {
      .v1 {
        box-sizing: border-box !important;
        padding: 20px 55px !important;
      }
    }
  }
}
.contacts {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .contacts_m1 {
    width: 100%;
    height: 900px;
    position: relative;
    background: url(../assets/i28.jpg) no-repeat center center;
    .v1 {
      width: 600px;
      height: 360px;
      background: #fff;
      border-radius: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -300px;
      margin-top: -180px;
      padding: 30px;
      .v1-t {
        overflow: hidden;
        text-align: center;
        font-size: 26px;
        padding-bottom: 20px;
      }
      .v1-m {
        display: flex;
        .l {
          flex: 1;
          .p1 {
            font-size: 18px;
            color: #000;
          }
          .p2 {
            font-size: 14px;
            color: #808080;
            margin-top: 10px;
            line-height: 30px;
            span {
              color: #000;
              font-size: 22px;
            }
          }
          .p3 {
            font-size: 14px;
            color: #808080;
            margin-top: 10px;
            line-height: 30px;
          }
          .p4 {
            font-size: 14px;
            color: #808080;
            line-height: 30px;
          }
          .p5 {
            font-size: 14px;
            color: #808080;
            line-height: 30px;
            margin-top: 10px;
            .i {
              width: 30px;
              height: 30px;
              display: inline-block;
              position: relative;
              margin-right: 10px;
              a {
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 50px;
                text-align: center;
                line-height: 30px;
                i {
                  font-size: 18px;
                  color: #000;
                }
              }
            }
          }
        }
        .r {
          width: auto;
          .p1 {
            text-align: center;
            font-size: 20px;
            color: #000;
          }
          .p2 {
            text-align: center;
            margin-top: 20px;
            img {
              width: 128px;
              height: 128px;
            }
          }
        }
      }
    }
  }
}
</style> 