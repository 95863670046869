import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

import user from './modules/user'

import createPersistedstate from 'vuex-persistedstate'
//import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const store = new Vuex.Store({
  getters,
  modules: {
    user,
  },
  plugins: [createPersistedstate({
  // key:['user_ctp_list','maketArr'],
   // key:'user_ctp_list',
    // reducer(val){
    //   console.log(val)
    //   return{
    //     user_ctp_list:val.user.user_ctp_list,
    //     maketArr:val.user.maketArr
    //   }
    // }
  // 存数据的key名   自定义的  要有语义化
     paths: ['user'] // 要把那些模块加入缓存
  }) 
  ] 

})

export default store
