<template>
  <div class="team">
    <div class="team_m1">
      <div class="m flex">
        <p style="color: #fff">
          专注量化CTA策略，使用机器学习处理海量数据、回归分析建立交易模型、<br />分析期货市场交易走势，连续多年取得稳健收益
        </p>
      </div>
    </div>
    <div class="team_m2">
      <div class="m">
        <div class="li bg1">
          <p class="p1">领军人物</p>
          <p class="p2">
            「多元」顶配头脑的跨学科合作，专注量化CTA策略，连续三届中电投先融期货实盘大赛冠军
          </p>
          <p class="p3">
            <a href="javascript:void(0)"
              >进一步了解<i class="icon iconfont icon-jiantou_liebiaoshouqi"></i
            ></a>
          </p>
        </div>
        <div class="li bg2">
          <p class="p1">投研团队</p>
          <p class="p2">
            「融合」技术信念的协同效应，科学家、IT极客、行业分析师的共同体，创造机器理解金融市场。
          </p>
          <p class="p3">
            <a href="javascript:void(0)"
              >进一步了解<i class="icon iconfont icon-jiantou_liebiaoshouqi"></i
            ></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  components: {},
  name: "team",
  data() {
    return {};
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
    //   addEventListener("scroll", this.handleScroll);
    //   addEventListener("scroll", this.handleAnimate);
    // });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },

    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
@media (max-width: 975px) {
  .team {
  }
}
.team {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .team_m2 {
    overflow: hidden;
    margin: 90px 0px;
    .m {
      display: flex;
      width: 1200px;
      height: auto;
      margin: 0px auto;
      .bg1 {
        background: url(../assets/i29.jpg) no-repeat center center;
      }
      .bg2 {
        background: url(../assets/i30.jpg) no-repeat center center;
      }
      .li {
        flex: 1;
        width: 470px;
        height: 798px;
        padding: 100px 60px;
        .p1 {
          overflow: hidden;
          text-align: center;
          font-size: 36px;
          font-weight: bold;
          color: #333;
        }
        .p2 {
          overflow: hidden;
          text-align: center;
          font-size: 20px;
          color: #808080;
          line-height: 30px;
          margin-top: 30px;
        }
        .p3 {
          overflow: hidden;
          text-align: center;
          font-size: 18px;
          color: #333;
          line-height: 30px;
          margin-top: 30px;
          a {
            color: #333;
          }
        }
        &:last-child {
        margin-left: 20px;
      }
      }
     
    }
  }
  .team_m1 {
    background-image: url(../assets/i31.jpg);
    width: 100%;
    height: 589px;
    background-repeat: no-repeat;
    background-position: center center;
    .m {
      width: 1200px;
      height: auto;
      margin: 0px auto;
      display: flex;
      p {
        align-self: center;
        font-size: 24px;
        color: #000;
        line-height: 44px;
        padding-top: 240px;
      }
    }
  }
}
</style> 