<template>
  <div class="company">
    <div class="company_m1">
      <div class="m">
        <div class="ms">
          <p class="p1">OUR<br />COMPANY</p>
          <p class="p2">公司简介</p>
          <div class="content">
            <p>
              重庆大凡私募证劵投资基金管理有限公司，成立于2014年，是中国证券投资基金业协会观察会员，重庆市渝北区重点企业。
            </p>
            <p>
              公司专注二级市场量化投资，在市场中寻找风险收益比最佳的交易机会，服务群体涵盖高净值客户及FOF、期货公司自营资金等各大金融机构，进入多家证券、期货公司白名单，多次荣获行业相关奖项。
            </p>
            <p>
              大凡人遵循“因天下之力，以生天下之财；取天下之财，以供天下之费”的共赢理念。我们希望与天下英才一同深耕量化投资领域，在市场中寻找风险收益比最佳的交易机会，为客户创造价值。
            </p>
            <p><br /></p>
          </div>
        </div>
      </div>
    </div>
    <div class="company_m2 animated"  data-ani="fadeInUp">
      <div class="m">
        <div class="ms">
          <p class="p1">投资理念</p>
          <div class="content">
            <p>坚持风险控制放在首位、量化投资为发展核心</p>
            <p style="font-size: medium; white-space: normal">
              <span style="font-size: 20px"></span
              ><br style="font-size: 16px" />
            </p>
            <p><br /></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  components: {},
  name: "company",
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
     // this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },

    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
@media (max-width: 975px) {
  .company {
  }
}
.company {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .company_m2 {
    background: white;
    padding: 40px 0px;
    .m {
      display: flex;
      background: url(../assets/i38.jpg) no-repeat right center;
      height: 660px;
      width: 1200px;
      background-size: 45%;
      margin: 0px auto;
      .ms {
        align-self: center;
        overflow: hidden;
        width: 550px;
        .p1 {
          font-size: 40px;
          color: #333;
        }
        .content {
          margin-top: 50px;
          width: 100%;
          overflow: hidden;
          font-size: 15px;
          color: #666;
          line-height: 30px;
          p {
            font-size: 18px;
            color: #333;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .company_m1 {
    width: 100%;
    overflow: hidden;
    height: 690px;
    background: url(../assets/i36.jpg) no-repeat center center;
    .m {
      width: 1200px;
      margin: 0px auto;
      display: flex;
      height: 100%;
      .ms {
        align-self: center;
        margin-top: 60px;
        overflow: hidden;
        .p1 {
          font-size: 30px;
          color: #fff;
        }
        .p2 {
          font-size: 48px;
          color: #fff;
        }
        .content {
          margin-top: 40px;
          width: 100%;
          overflow: hidden;
          font-size: 15px;
          color: #666;
          line-height: 30px;
          p {
            margin-bottom: 15px;
            font-size: 18px;
            color: #fff;
          }
        }
      }
    }
  }
}
</style> 