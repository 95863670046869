<template>
  <div style="box-sizing: border-box; padding: 10px 20px">
    <div style="text-align: left">
      <span style="font-size: 18px; font-weight: 700; line-height: 48px"
        >区间收益</span
      >
    </div>
    <div>
      <ul style="width: 100%; padding: 0; margin: 0; text-align: left">
        <!-- v-for相当于 JS 中的循环遍历数组，其中 item 表示数组的每一项对象（可自定义名称），index表示下标（可自定义名称） -->
        <!-- :key 是 v-bind:key 的简写，此处是为了避免数据重复渲染 -->
        <!-- 该标签的类名采用三目运算符进行判断，其中 :class 表示动态绑定数据 -->
        <!-- @click="事件名称" 表示点击则出发对应事件 -->
        <!-- {{}} 是插值表达式的写法，可以直接引入 vue 实例中的属性 -->
        <li
          v-for="(item, index) in arr"
          :key="item.id"
          style="
            list-style: none;
            font-size: 13px;
            padding: 3px 10px;
            cursor: pointer;
            display: inline-block;
          "
          :class="[
            'list-group-item',
            num === index ? 'bg-primary text-white' : '',
          ]"
          @click="tabActive(index)"
        >
          {{ item.text }}
        </li>
      </ul>
    </div>

    <div>
      <el-row>
        <el-col :span="24" element-loading-text="加载中...">
          <div id="myChart4" :style="{ width: '100%', height: '600px' }"></div>
        </el-col>
      </el-row>
    </div>
    <!-- <div>
      <el-table
      :data="IntervalReturnsTable"
      :span-method="objectSpanMethod"
    
      style="width: 100%; margin-top: 20px">
      <el-table-column
        prop="id"
        label="年份"
        width="180">
      </el-table-column>
      <el-table-column
        prop="name"
        label="简称">
      </el-table-column>
      <el-table-column
        prop="amount1"
        label="1月">
      </el-table-column>
      <el-table-column
        prop="amount2"
        label="2月">
      </el-table-column>
      <el-table-column
        prop="amount3"
        label="3月">
      </el-table-column>
      <el-table-column
        prop="amount4"
        label="4月">
      </el-table-column>
      <el-table-column
        prop="amount5"
        label="5月">
      </el-table-column>
      <el-table-column
        prop="amount6"
        label="6月">
      </el-table-column>
      <el-table-column
        prop="amount7"
        label="7月">
      </el-table-column>
      <el-table-column
        prop="amount8"
        label="8月">
      </el-table-column>
      <el-table-column
        prop="amount9"
        label="9月">
      </el-table-column>
      <el-table-column
        prop="amount10"
        label="10月">
      </el-table-column>
      <el-table-column
        prop="amount11"
        label="11月">
      </el-table-column>
      <el-table-column
        prop="amount12"
        label="12月">
      </el-table-column>
      <el-table-column
        prop="amountAll"
        label="年份统计">
      </el-table-column>
      <el-table-column
        prop="amountAll2"
        label="月胜率">
      </el-table-column>
    </el-table>
    </div> -->
    <div style="margin-top: 20px">
      <div style="text-align: left">
        <span style="font-size: 18px; font-weight: 700">基金规模</span>
        <span
          style="
            color: #0006;
            font-size: 12px;
            text-align: left;
            margin-left: 10px;
          "
        >
          <i class="el-icon-question"></i>
          数据更新于：{{ fund_data.update_date }}</span
        >
      </div>
      <el-row>
        <el-col :span="24" element-loading-text="加载中...">
          <div id="myChart5" :style="{ width: '100%', height: '400px' }"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
let myChart3 = null;
let myChart4 = null;
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default() {
        return {};
      },
    },
    chartData_m1: {
      type: Array,
      default() {
        return {};
      },
    },

    fund_data: {
      type: Object,
      default: "",
    },
  },
  watch: {
    chartData: {
      handler: function (newval, oldval) {
        this.$nextTick(() => {
          this.drawLine();
          // this.drawLine2();
        });
      },
    },
    chartData_m1: {
      handler: function (newval, oldval) {
        this.$nextTick(() => {
          this.drawLine2();
        });
      },
    },
  },
  data() {
    return {
      arr: [
        { text: "月度收益", id: 1 },
        // { text: "季度收益", id: 2 },
        // { text: "年度收益", id: 3 },
        // { text: "阶段收益", id: 4 },
      ],
      num: 0,
      IntervalReturnsTable: [
        {
          id: "2024",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
          amount4: "621",
          amount5: "2.2",
          amount6: 17,
          amount7: "621",
          amount8: "2.2",
          amount9: "621",
          amount10: "2.2",
          amount11: 17,
          amount12: "621",
          amountAll: "2.2",
          amountAll2: 17,
        },
        {
          id: "2024",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
          amount4: "621",
          amount5: "2.2",
          amount6: 17,
          amount7: "621",
          amount8: "2.2",
          amount9: "621",
          amount10: "2.2",
          amount11: 17,
          amount12: "621",
          amountAll: "2.2",
          amountAll2: 17,
        },
        {
          id: "2024",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
          amount4: "621",
          amount5: "2.2",
          amount6: 17,
          amount7: "621",
          amount8: "2.2",
          amount9: "621",
          amount10: "2.2",
          amount11: 17,
          amount12: "621",
          amountAll: "2.2",
          amountAll2: 17,
        },
        {
          id: "2024",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
          amount4: "621",
          amount5: "2.2",
          amount6: 17,
          amount7: "621",
          amount8: "2.2",
          amount9: "621",
          amount10: "2.2",
          amount11: 17,
          amount12: "621",
          amountAll: "2.2",
          amountAll2: 17,
        },
        {
          id: "2024",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
          amount4: "621",
          amount5: "2.2",
          amount6: 17,
          amount7: "621",
          amount8: "2.2",
          amount9: "621",
          amount10: "2.2",
          amount11: 17,
          amount12: "621",
          amountAll: "2.2",
          amountAll2: 17,
        },
        {
          id: "2024",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
          amount4: "621",
          amount5: "2.2",
          amount6: 17,
          amount7: "621",
          amount8: "2.2",
          amount9: "621",
          amount10: "2.2",
          amount11: 17,
          amount12: "621",
          amountAll: "2.2",
          amountAll2: 17,
        },
        {
          id: "2024",
          name: "王小虎",
          amount1: "621",
          amount2: "2.2",
          amount3: 17,
          amount4: "621",
          amount5: "2.2",
          amount6: 17,
          amount7: "621",
          amount8: "2.2",
          amount9: "621",
          amount10: "2.2",
          amount11: 17,
          amount12: "621",
          amountAll: "2.2",
          amountAll2: 17,
        },
      ],
    };
  },
  mounted() {
    myChart3 = this.$echarts.init(document.getElementById("myChart5"));
    myChart4 = this.$echarts.init(document.getElementById("myChart4"));
    this.drawLine();
    this.drawLine2();
    if (myChart3 || myChart4) {
      window.addEventListener("resize", function () {
        myChart3.resize();
        myChart4.resize();
      });
    }
  },
  destroyed() {},
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },

    tabActive(i) {
      this.num = i;
    },
    drawLine2() {
      // 基于准备好的dom，初始化echarts实例

      // 绘制图表
      let x2 = [];
      let y2 = [];
      let arrs = this.chartData_m1;
      for (let i = 0; i < arrs.length; i++) {
        x2.push(arrs[i].dates);
        y2.push(arrs[i].asset);
      }
      myChart3.setOption({
        grid: {
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: [this.fund_data.fund_name],
        },
        xAxis: {
          type: "category",
          data: x2,
        },
        yAxis: {
          type: "value",
          min: 1000000,
        },
        series: [
          {
            itemStyle: {
              normal: {
                color: "#E1322C", //图例颜色
                // borderWidth: 2,
                // borderColor: "#f15441",
                // lineStyle: { color: "#f15441", width: 1 }
              },
            },
            data: y2,
            type: "line",
          },
        ],
      });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例

      // 绘制图表
      let arrs = this.chartData;
      let x = [];
      let y = [];
      for (const key in arrs) {
        x.push(key);
        y.push(arrs[key]);
      }
      myChart4.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (val) {
            let string = val[0].name + "<br>";
            val.forEach((v) => {
              string += `<div style='display: flex;align-items: center;justify-content: space-between;'><span>${v.marker}${v.seriesName}</span>&nbsp; &nbsp; <span style='font-weight: 700;'>${v.value}%</span></div>`;
            });
            return string;
          },
        },
        grid: {
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: x,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}" + "%",
            },
          },
        ],
        series: [
          {
            name: this.fund_data.fund_name,
            type: "bar",
            barGap: 0,
            itemStyle: {
              color: function (params) {
                // params是一个包含数据信息的对象
                var colorList = ["#E1322D", "#0F9F4F"]; // 正数和负数的颜色列表
                return params.value < 0 ? colorList[1] : colorList[0];
              },
            },
            emphasis: {
              focus: "series",
            },
            data: y,
          },
          // {
          //   name: "Steppe",
          //   type: "bar",

          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [220, 182, 191, 234, 290],
          // },
          // {
          //   name: "Desert",
          //   type: "bar",

          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [150, 232, 201, 154, 190],
          // },
          // {
          //   name: "Wetland",
          //   type: "bar",

          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [98, 77, 101, 99, 40],
          // },
        ],
      });
    },
  },
};
</script>
<style >
</style> 