<template>
  <div style="box-sizing: border-box; padding: 10px 20px">
    <div style="text-align: left">
      <span style="font-size: 18px; font-weight: 700">收益风险指标</span>
      <span
        style="
          color: #0006;
          font-size: 12px;
          text-align: left;
          margin-left: 10px;
        "
      >
        <i class="el-icon-question"></i>
        数据更新于：2024-06-28</span
      >
    </div>
    <div class="nav_btn" style="text-align: left; padding: 30px 0px">
      <span
        style="
          font-size: 14px;
          padding: 4px 6px;
          background-color: #e1322d1a;
          color: rgba(225, 50, 45);
        "
        >近一年</span
      >
    </div>
    <div class="table_box" style="text-align: left">
      <el-row :gutter="10">
        <el-col :span="12" style="box-sizing: border-box; padding: 20px;">
          <div style="color: #0009; font-weight: 600; font-size: 14px">
            收益能力
          </div>
          <el-table :data="tabdata1" style="width: 100%">
            <el-table-column prop="name" label="指标名称">
            </el-table-column>
            <el-table-column prop="name2" label="本基金" >
            </el-table-column>
            <el-table-column prop="name3" label="同类平均"> </el-table-column>
            <el-table-column prop="name4" label="同类排名"> </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12" style="box-sizing: border-box; padding: 20px;">
            <div style="color: #0009; font-weight: 600; font-size: 14px">
             投资性价比
          </div>
          <el-table :data="tabdata2" style="width: 100%">
            <el-table-column prop="name" label="指标名称">
            </el-table-column>
            <el-table-column prop="name2" label="本基金" >
            </el-table-column>
            <el-table-column prop="name3" label="同类平均"> </el-table-column>
            <el-table-column prop="name4" label="同类排名"> </el-table-column>
          </el-table></el-col>
       
      </el-row>
      <el-row :gutter="10"> 
            <el-col :span="12" style="box-sizing: border-box; padding: 20px;">
            <div style="color: #0009; font-weight: 600; font-size: 14px">
                  抗风险能力
          </div>
          <el-table :data="tabdata3" style="width: 100%">
            <el-table-column prop="name" label="指标名称">
            </el-table-column>
            <el-table-column prop="name2" label="本基金" >
            </el-table-column>
            <el-table-column prop="name3" label="同类平均"> </el-table-column>
            <el-table-column prop="name4" label="同类排名"> </el-table-column>
          </el-table></el-col>
        <el-col :span="12" style="box-sizing: border-box; padding: 20px;">
            <div style="color: #0009; font-weight: 600; font-size: 14px">
                  超额指标
          </div>
          <el-table :data="tabdata4" style="width: 100%">
            <el-table-column prop="name" label="指标名称">
            </el-table-column>
            <el-table-column prop="name2" label="本基金" >
            </el-table-column>
            <el-table-column prop="name3" label="同类平均"> </el-table-column>
            <el-table-column prop="name4" label="同类排名"> </el-table-column>
          </el-table></el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tabdata1: [{
            name: '收益',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '年化',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: 'Alpha',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '月胜率',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '进攻能力',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          }],
          tabdata2: [{
            name: '夏普比率',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '索提诺',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '卡玛比率',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
        ],
        tabdata3: [{
            name: '回撤',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '回撤修复',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '贝塔',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '年化波动率',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '下行风险',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '防守能力',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          }
      ],
          tabdata4: [{
            name: '超额收益',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '超额年化',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '超额回撤',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '超额回撤修复',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '超额夏普比率',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '超额索提诺',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          },
          {
            name: '超额卡玛比率',
            name2: '33.8%',
            name3: '33.8%',
            name4:'1/30'
          }
          
      
      ],
    };
  },
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>
<style >
</style> 