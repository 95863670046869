<template>
  <div class="goodsteam">
    <div class="goodsteam_box">
      <div class="goodsteam_box_bg">
        <div class="goodsteam_box_text">
          <p style="color: #fff">
            主流交易算法框架下的量化CTA基金，<br />为客户提供优质的投资产品选择
          </p>
        </div>
      </div>
      <div class="goodsteam_m8 animated" data-ani="fadeInUp">
        <div class="m">
          <p class="p1">净值周报</p>
          <p class="p2">
            主流交易算法框架下的量化CTA基金，为客户提供优质的投资产品选择。
          </p>
          <p class="p3">
            <a href="javascript:void(0)" @click="routerFun('/bonus')"
              >立即查看</a
            >
          </p>
        </div>
      </div>
      <div class="goodsteam_box_money animated" data-ani="fadeInUp">
        <div class="goodsteam_m">
          <div class="ms">
            <p class="p1">分红公告</p>
            <ul>
              <li @click="routerFun('/bonus')">
                <a href="javascript:void(0)">
                  <span>·</span>大凡0032 2021年10月分红
                </a>
              </li>
              <li @click="routerFun('/bonus')">
                <a href="javascript:void(0)">
                  <span>·</span>大凡12号 2021年10月分红
                </a>
              </li>
              <li @click="routerFun('/bonus')">
                <a href="javascript:void(0)">
                  <span>·</span>大凡1号 2021年10月分红
                </a>
              </li>
            </ul>
            <p class="p2">...</p>
            <p class="p3">
              <a href="javascript:void(0)" @click="routerFun('/bonus')"
                >查看更多 <i class="icon iconfont icon-jiantou_xiangyou_o"></i
              ></a>
            </p>
          </div>
        </div>
      </div>
      <div class="goodsteam_news animated" data-ani="fadeInUp">
        <div class="goodsteam_news_m7">
          <p class="p1">近40个主流品种的挖掘和分析</p>
          <p class="p2">
            专注CTA——通过对国内期货市场十多年来近40个主流品种的基本面、技术面数据的挖掘和分析，<br />
            发现具有概率优势的交易信号，从而捕捉趋势性的盈利机会。
          </p>
          <div class="v1 flex">
            <div class="v2"><img src="../assets/i9.jpg" /></div>
            <div class="v3"><img src="../assets/i10.jpg" /></div>
            <div class="v4"><img src="../assets/i11.jpg" /></div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>

      <div class="goodsteam_m9 animated" data-ani="fadeInUp">
        <div class="m">
          <p class="p1s">如何持有大凡产品</p>
          <p class="p2s">
            *据监管要求，客户需填报真实身份信息后才能了解私募产品。大凡将对客户信息严格保密
          </p>
          <div class="v">
            <div class="v1">
              <p class="p1">1.</p>
              <p class="p1-1">我能买吗？</p>
              <p class="p2">
                私募产品起投金额为100万元,且需满足以下合格投资者要求:
              </p>
              <p class="p2">个人</p>
              <p class="p3">金融资产≥300万元</p>
              <p class="p3">或近三年年均收入≥50万元</p>
              <p class="p2">企业</p>
              <p class="p3">净资产≥1000万元</p>
            </div>
            <div class="v1 v1s">
              <p class="p1">2.</p>
              <p class="p1-1">选择产品</p>
              <p class="p2">产品业绩/策略介绍/费率条款</p>
              <p class="p2-2">
                <a href="javascript:void(0)" @click="routerFun('/bonus')"
                  >立即查看产品业绩</a
                >
              </p>
              <p class="p3">首次查看「产品业绩」需填写风险测评,预计耗时3分钟</p>
            </div>
            <div class="v1">
              <p class="p1">3.</p>
              <p class="p1-1">签约购买</p>
              <p class="p2">
                联系我们，专人与您对接签约申购同时支持线上/现场签约
              </p>
              <p class="p2 mt20">联系方式</p>
              <div class="v5 flex">
                <img src="../assets/i5.jpg" alt="" />
                <div class="r">
                  <p class="p4">关注公众号/拨打电话</p>
                  <p class="p5">
                    <i class="icon iconfont icon-24gf-phoneLoudspeaker"></i
                    >023-67848897
                  </p>
                  <p class="p5">67849055</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      class="wjbox"
      :visible.sync="wjbox"
      custom-class="wjboxwidth"
      :before-close="handleClose"
    >
      <div slot="title" class="dialog-title" style="text-align: center">
        私募投资基金投资者风险问卷调查
      </div>
      <el-steps align-center :active="active" finish-status="success">
        <el-step title="实名认证"> </el-step>
        <el-step title="问卷调查"></el-step>
        <el-step title="上传证明"></el-step>
      </el-steps>

      <div class="steps_box">
        <div class="con" v-if="active == 0">
          <div class="ms1">
            <p class="ti">
              请如实填写《私募基金投资者风险问卷调查》，并了解了自己的风险承受类型和适合购买的产品类型。
            </p>
          </div>
        </div>
        <div class="con" v-if="active == 1">
          <div class="ms1">
            <p class="pt fxts1" style="">
              <span>风险提示：</span
              >私募基金投资需承担各类风险，本金可能遭受损失。同时，私募基金投资还要考虑市场风险、信用风险、流动性风险、操作风险等各类投资风险。选择与自己风险识别能力和风险承受能力相匹配的私募基金。
            </p>
          </div>
        </div>

        <div class="steps_m1" v-if="active == 0">
          <div style="text-align: center; padding: 0px 0px 15px 0px">
            <el-radio-group
              v-model="steps_m2_radio"
              v-removeAria
              @change="steps_m2_radio_change"
            >
              <el-radio class="custom-radio-red" :label="0">个人版</el-radio>
              <el-radio class="custom-radio-red" :label="1">机构版</el-radio>
            </el-radio-group>
          </div>
          <div class="steps_m2_radio_personal" v-if="steps_m2_radio == 0">
            <el-form
              v-if="steps_m2_radio == 0"
              label-position="right"
              label-width="100px"
              :model="userActive_m1_form"
              :rules="rules"
              ref="userActive_m1_form_ruleForm"
            >
              <el-form-item label="姓名:" prop="username">
                <el-input v-model="userActive_m1_form.username"></el-input>
              </el-form-item>
              <el-form-item label="身份证号:" prop="userid">
                <el-input v-model="userActive_m1_form.userid"></el-input>
              </el-form-item>
              <!-- <el-form-item label="手机号:" prop="phone">
                <el-input v-model="userActive_m1_form.phone"></el-input>
              </el-form-item>
              <el-form-item label="验证码:" prop="code">
                <el-input v-model="userActive_m1_form.code">
                  <template #suffix>
                    <div v-if="messageCodeVis" class="second-text">
                      {{ countdown }}秒后重新获取
                    </div>
                    <el-button
                      v-else
                      type="primary"
                      class="codeBtn"
                      link
                      @click="getCode"
                      >获取验证码</el-button
                    >
                  </template>
                </el-input>
              </el-form-item> -->

              <!-- <el-form-item label="请确认您是否是合格投资者" prop="resource">
              <el-radio-group v-model="userActive_m1_form.resource">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
          </el-form-item> -->
            </el-form>
          </div>
          <div
            class="steps_m2_radio_box_institution"
            v-if="steps_m2_radio == 1"
          >
            <el-form
              v-if="steps_m2_radio == 1"
              label-position="right"
              label-width="100px"
              :model="userActive_m1_institution_form"
              :rules="rules"
              ref="userActive_m1_institution_form_ruleForm"
            >
              <el-form-item label="公司名称:" prop="institution_username">
                <el-input
                  v-model="userActive_m1_institution_form.institution_username"
                ></el-input>
              </el-form-item>
              <el-form-item label="您的姓名:" prop="username">
                <el-input
                  v-model="userActive_m1_institution_form.username"
                ></el-input>
              </el-form-item>
              <el-form-item label="证件类型:" prop="user_type">
                <el-select
                  style="width: 100%"
                  :popper-append-to-body="false"
                  v-model="userActive_m1_institution_form.user_type"
                  placeholder="请选择证件类型"
                >
                  <el-option
                    label="统一社会信用代码"
                    value="统一社会信用代码"
                  ></el-option>
                  <el-option
                    label="组织机构代码证"
                    value="组织机构代码证"
                  ></el-option>
                  <el-option
                    label="工商营业执照"
                    value="工商营业执照"
                  ></el-option>
                  <el-option label="税务登记证" value="税务登记证"></el-option>
                  <el-option
                    label="事业单位法人证书"
                    value="事业单位法人证书"
                  ></el-option>
                  <el-option label="国税" value="国税"></el-option>
                  <el-option label="地税" value="地税"></el-option>
                  <el-option label="基金会" value="基金会"></el-option>
                  <el-option label="行政机关" value="行政机关"></el-option>
                  <el-option label="其他" value="其他"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="证件号码:" prop="user_type_id">
                <el-input
                  v-model="userActive_m1_institution_form.user_type_id"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="手机号:" prop="phone">
                <el-input
                  v-model="userActive_m1_institution_form.phone"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码:" prop="code">
                <el-input v-model="userActive_m1_institution_form.code">
                  <template #suffix>
                    <div v-if="messageCodeVis" class="second-text">
                      {{ countdown }}秒后重新获取
                    </div>
                    <el-button
                      v-else
                      type="primary"
                      class="codeBtn"
                      link
                      @click="getCode"
                      >获取验证码</el-button
                    >
                  </template>
                </el-input>
              </el-form-item> -->

              <!-- <el-form-item label="请确认您是否是合格投资者" prop="resource">
              <el-radio-group v-model="userActive_m1_form.resource">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
          </el-form-item> -->
            </el-form>
          </div>
        </div>

        <div class="steps_m2" v-if="active == 1">
          <div v-for="(item, key) in userActive_m1_Arr" :key="key">
            <template>
              <div v-if="userActive_m1_Arr_step == item.step">
                <div>
                  <p class="pw">
                    <span>{{ item.step }}/{{ userActive_m1_Arr.length }}</span
                    >{{ item.title }}
                  </p>
                </div>
                <div>
                  <el-radio-group
                    v-model="item.actives"
                    style="width: 100%"
                    v-removeAria
                  >
                    <el-radio
                      :label="item.id"
                      v-for="item in item.listVal"
                      :key="item.id"
                      @click.native.prevent="clickitem(item, item.id)"
                      border
                      class="radioClass"
                      >{{ item.text }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="steps_m3" v-if="active == 2">
          <div style="margin-bottom: 10px; font-size: 16px">
            您的问卷调查结果为 ：
            <span style="color: #e80101">{{
              userActive_m1_Arr_pos_text.type
            }}</span>
            适合<span style="color: #e80101">{{
              userActive_m1_Arr_pos_text.text
            }}</span
            >产品
          </div>
          <span
            style="display: inline-block; margin-bottom: 10px; color: #e80101"
            >请上传相关的资质文件</span
          >

          <el-upload
            class="upload-demo"
            drag
            :limit="1"
            accept=".jpg, .png, .pdf"
            action=""
            :http-request="uploadFile"
            :before-upload="beforeUpload"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将图片拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png/pdf文件，且不超过2MB
            </div>
          </el-upload>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="padding: 0 80px">
        <el-button
          v-if="active != 1"
          style="width: 100%; margin: 0 auto; background: #e80101; border: none"
          type="primary"
          @click="activeFun"
          >{{ activeFun_title }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
export default {
  components: {},
  name: "goodsteam",
  computed: {
    ...mapGetters(["UserId", "token", "is_active"]),
  },
  data() {
    var validatorIdCard = (rule, value, callback) => {
      // 地区
      var aCity = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",

        21: "辽宁",
        22: "吉林",
        23: "黑龙江",

        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",

        41: "河南",
        42: "湖北",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",

        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏",

        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",

        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外",
      };

      // 验证长度

      if (!/^\d{17}(\d|x)$/i.test(value)) {
        callback(
          new Error("您输入的身份证号长度或格式错误，请输入正确的身份证号")
        );

        return;
      }

      // 验证前两位是否为省份代码

      value = value.replace(/x$/i, "a");

      if (aCity[parseInt(value.substr(0, 2))] == null) {
        callback(
          new Error("您输入的身份证号长度或格式错误，请输入正确的身份证号")
        );

        return;
      }

      // 身份证上的出生年月校验

      var sBirthday =
        value.substr(6, 4) +
        "-" +
        Number(value.substr(10, 2)) +
        "-" +
        Number(value.substr(12, 2));

      var d = new Date(sBirthday.replace(/-/g, "/"));

      if (
        sBirthday !=
        d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
      ) {
        callback(new Error("您输入的身份证号不合法，请输入正确的身份证号"));

        return;
      }

      // 身份证校验位判断

      var iSum = 0;

      for (var i = 17; i >= 0; i--) {
        iSum += (Math.pow(2, i) % 11) * parseInt(value.charAt(17 - i), 11);
      }

      if (iSum % 11 != 1) {
        callback(new Error("您输入的身份证号不合法，请输入正确的身份证号"));

        return;
      }

      callback();
    };

    return {
      formData_v: "",
      IS_ACTIVE: 0,
      steps_m2_radio: 0,
      activeFun_title: "下一步",
      wjbox: false,
      active: 0,
      radio1: "",
      userActive_m1_Arr_pos_text: {
        type: "",
        text: "",
        num: "",
      },
      userActive_m1_Arr_step: 1,
      userActive_m1_Arr: [
        {
          step: 1,
          title: "您的年龄介于",
          listVal: [
            {
              id: 1,
              score: 2,
              text: "A、18-30岁",
            },
            {
              id: 2,
              score: 4,
              text: "B、31-50岁",
            },
            {
              id: 3,
              score: 6,
              text: "C、51-65岁",
            },
            {
              id: 4,
              score: 8,
              text: "D、高于65岁",
            },
          ],
        },
        {
          step: 2,
          title: "您认为目前的投资经验属于哪一种",
          listVal: [
            {
              score: 2,
              text: "A、无经验投资",
            },
            {
              score: 4,
              text: "B、初级投资者",
            },
            {
              score: 6,
              text: "C、中级投资者",
            },
            {
              score: 8,
              text: "D、高级投资者",
            },
          ],
        },
      ],
      userActive_m1_Arr_post: [],
      userActive_m1_form: {
        username: "",
        userid: "",
      },
      userActive_m1_institution_form: {
        institution_username: "",
        username: "",
        user_type_id: "",
        user_type: "",
        phone: "",
        code: "",
      },
      messageCodeVis: false,
      countdown: 60,
      timer: null,
      rules: {
        institution_username: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
          { min: 1, max: 6, message: "长度在 1 到 6 个字符", trigger: "blur" },
        ],
        userid: [
          // { required: true, validator: validatorIdCard, trigger: "blur" }
          { required: true, message: "请输入您的身份证号码", trigger: "blur" },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证号码",
            trigger: "blur",
          },
        ],
        num: [{ required: true, message: "请选择证件类型", trigger: "blur" }],
        user_type: [
          { required: true, message: "请选择证件类型", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        user_type_id: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    active: {
      handler: function (newval, oldval) {
        if (newval == 2) {
          this.activeFun_title = "提交";
        } else {
          this.activeFun_title = "下一步";
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      // this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    dialog_close_fn() {},
    uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("file_type", "img");
      let arrs = this.userActive_m1_Arr_post;

      arrs.unshift(this.userActive_m1_Arr_pos_text);

      if (this.steps_m2_radio == 0) {
        arrs.unshift(this.userActive_m1_form);
      } else {
        arrs.unshift(this.userActive_m1_institution_form);
      }

      let str = JSON.stringify(arrs);
      str = str.split("},").join("}\n");
      formData.append("text", JSON.stringify(str));

      //123123123123123123
      this.formData_v = formData;
    },

    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传的文件大小不能超过 2MB!");
      }

      return isLt2M;
    },
    clickitem(el, inde) {
      let arrs = this.userActive_m1_Arr_post;
      arrs.push(el);
      this.userActive_m1_Arr_post = arrs;
      if (this.userActive_m1_Arr_step == this.userActive_m1_Arr.length) {
 
        let obk = {
          type: "",
          text: "",
          num: "",
        };
        let nums = 0;
        let fensu = this.userActive_m1_Arr_post;
        for (let k = 0; k < fensu.length; k++) {
          nums += fensu[k].score;
        }
        if (nums > 0 && nums <= 33) {
          //
          obk.num = nums;
          obk.text = "保守型";
          obk.type = "低风险";
        } else if (nums > 34 && nums <= 45) {
          obk.num = nums;
          obk.text = "稳健型";
          obk.type = "中低风险";
        } else if (nums > 46 && nums <= 60) {
          obk.num = nums;
          obk.text = "平衡型";
          obk.type = "中风险";
        } else if (nums > 61 && nums <= 80) {
          obk.num = nums;
          obk.text = "成长型";
          obk.type = "中高风险";
        } else if (nums > 81) {
          obk.num = nums;
          obk.text = "进取型";
          obk.type = "高风险";
        }
        this.userActive_m1_Arr_pos_text = obk;
        this.active++;
      }

      this.userActive_m1_Arr_step++;
    },

    getCode() {
      // 获取验证码的逻辑
      this.messageCodeVis = true;
      this.startCountdown();
    },
    startCountdown() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.countdown = 60;
      this.timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.messageCodeVis = false;
        }
      }, 1000);
    },
    user_ajax_m1() {
      let userform = this.userActive_m1_form;
      if (this.steps_m2_radio == 0) {
        let arr = [
          {
            step: 1,
            title: "您的主要收入来源是",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 4,
                text: "A、工资、劳务报酬",
              },
              {
                id: 2,
                score: 4,
                text: "B、生产经营所得",
              },
              {
                id: 3,
                score: 7,
                text: "C、利息、股息、转让等金融性资产收入",
              },
              {
                id: 4,
                score: 5,
                text: "D、出租、出售房地产等非金融性资产收入",
              },
              {
                id: 5,
                score: 1,
                text: "E、无 固 定 收 入",
              },
            ],
          },
          {
            step: 2,
            title: "您的家庭可支配年收入为（折合人民币）？ ",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、50 万元以下",
              },
              {
                id: 2,
                score: 5,
                text: "B、50—100 万元",
              },
              {
                id: 3,
                score: 6,
                text: "C、100—500 万元",
              },
              {
                id: 4,
                score: 7,
                text: "D、 500—1000 万元",
              },
              {
                id: 5,
                score: 8,
                text: "E、1000 万元以上",
              },
            ],
          },
          {
            step: 3,
            title:
              "在您每年的家庭可支配收入中，可用于金融投资（储蓄存款除外）的比例为？",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、小于 10%",
              },
              {
                id: 2,
                score: 5,
                text: "B、10%至 25%",
              },
              {
                id: 3,
                score: 6,
                text: "C、25%至 50%",
              },
              {
                id: 4,
                score: 8,
                text: "D、大于 50%",
              },
            ],
          },
          {
            step: 4,
            title: "您是否有尚未清偿的数额较大的债务，如有，其性质是",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 8,
                text: "A、没有",
              },
              {
                id: 2,
                score: 6,
                text: "B、有，住房抵押贷款等长期定额债务",
              },
              {
                id: 3,
                score: 4,
                text: "C、有，信用卡欠款、消费信贷等短期信用债务",
              },
              {
                id: 4,
                score: 2,
                text: "D、有，亲戚朋友借款",
              },
            ],
          },
          {
            step: 5,
            title: "您的投资知识可描述为",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 3,
                text: "A、有限：基本没有金融产品方面的知识",
              },
              {
                id: 2,
                score: 5,
                text: "B、一般：对金融产品及其相关风险具有基本的知识和理解",
              },
              {
                id: 3,
                score: 8,
                text: "C、丰富：对金融产品及其相关风险具有丰富的知识和理解",
              },
            ],
          },
          {
            step: 6,
            title: "您的投资经验可描述为",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、除银行储蓄外，基本没有其他投资经验资",
              },
              {
                id: 2,
                score: 4,
                text: "B、购买过债券、保险等理财产品",
              },
              {
                id: 3,
                score: 7,
                text: "C、参与过股票、基金等产品的交易",
              },
              {
                id: 4,
                score: 9,
                text: "D、参与过权证、期货、期权等产品的交易",
              },
            ],
          },
          {
            step: 7,
            actives: "",
            title:
              "您有多少年投资基金、股票、信托、私募证券或金融衍生产品等风险投资品的经验？",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、没有经验",
              },
              {
                id: 2,
                score: 4,
                text: "B、少于 2 年",
              },
              {
                id: 3,
                score: 6,
                text: "C、2 至 5 年",
              },
              {
                id: 4,
                score: 8,
                text: "D、5 至 10 年",
              },
              {
                id: 5,
                score: 10,
                text: "10 年以上",
              },
            ],
          },
          {
            step: 8,
            title: "您计划的投资期限是多久？",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、1 年以下",
              },
              {
                id: 2,
                score: 4,
                text: "B、1 至 3 年",
              },
              {
                id: 3,
                score: 6,
                text: "C、3 至 5 年",
              },
              {
                id: 4,
                score: 8,
                text: "D、5 年以上",
              },
            ],
          },
          {
            step: 9,
            title: "您打算重点投资于哪些种类的投资品种？",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、债券、货币市场基金、债券基金等固定收益类投资品种",
              },
              {
                id: 2,
                score: 6,
                text: "B、股票、混合型基金、股票型基金等权益类投资品种",
              },
              {
                id: 3,
                score: 9,
                text: "C、期货、期权等金融衍生品",
              },
              {
                id: 4,
                score: 6,
                text: "D、其他产品或者服务",
              },
            ],
          },
          {
            step: 10,
            title: "以下哪项描述最符合您的投资态度？",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、厌恶风险，不希望本金损失，希望获得稳定回报",
              },
              {
                id: 2,
                score: 4,
                text: "B、保守投资，不希望本金损失，愿意承担一定幅度的收益 波动",
              },
              {
                id: 3,
                score: 7,
                text: "C、寻求资金的较高收益和成长性，愿意为此承担有限本金 损失",
              },
              {
                id: 4,
                score: 9,
                text: "D、希望赚取高回报，愿意为此承担较大本金损失",
              },
            ],
          },
          {
            step: 11,
            actives: "",
            title:
              "假设有两种投资：投资 A 预期获得 10%的收益，可能承担的损失非常小；投资 B 预期获得 30%的收益，但可能承担较大亏损。您会怎么支配您的投资：",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、全部投资于收益较小且风险较小的 A",
              },
              {
                id: 2,
                score: 4,
                text: "B、同时投资于 A 和 B，但大部分资金投资于收益较小且风险较小的 A",
              },
              {
                id: 3,
                score: 6,
                text: "C、同时投资于 A 和 B，但大部分资金投资于收益较大且风险较大的 B",
              },
              {
                id: 4,
                score: 8,
                text: "D、全部投资于收益较大且风险较大的 B",
              },
            ],
          },
          {
            step: 12,
            title: "您认为自己能承受的最大投资损失是多少？",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、10%以内",
              },
              {
                id: 2,
                score: 5,
                text: "B、10%-30% ",
              },
              {
                id: 3,
                score: 7,
                text: "C、 30%-50%",
              },
              {
                id: 4,
                score: 9,
                text: "D、超过 50%",
              },
            ],
          },
        ];
        this.userActive_m1_Arr_step = 1;
        this.userActive_m1_Arr = arr;
      } else {
        let arr2 = [
          {
            step: 1,
            title: "贵单位的性质",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、国有企事业单位 ",
              },
              {
                id: 2,
                score: 3,
                text: "B、非上市民营企业",
              },
              {
                id: 3,
                score: 4,
                text: "C、外资企业",
              },
              {
                id: 4,
                score: 5,
                text: "D、上市公司",
              },
            ],
          },
          {
            step: 2,
            title: "贵单位的净资产规模为",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、500 万元以下 ",
              },
              {
                id: 2,
                score: 3,
                text: "B、500 万元-2000 万元 ",
              },
              {
                id: 3,
                score: 5,
                text: "C、2000 万元-1 亿元",
              },
              {
                id: 4,
                score: 5,
                text: "D、超过 1 亿元",
              },
            ],
          },
          {
            step: 3,
            title: "贵单位年营业收入为",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、500 万元以下 ",
              },
              {
                id: 2,
                score: 3,
                text: "B、500 万元-2000 万元 ",
              },
              {
                id: 3,
                score: 4,
                text: "C、2000 万元-1 亿元",
              },
              {
                id: 4,
                score: 5,
                text: "D、超过 1 亿元",
              },
            ],
          },
          {
            step: 4,
            title: "贵单位证券账户资产为",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、300 万元以内 ",
              },
              {
                id: 2,
                score: 3,
                text: "B、300 万元-1000 万元  ",
              },
              {
                id: 3,
                score: 4,
                text: "C、1000 万元-3000 万元 ",
              },
              {
                id: 4,
                score: 5,
                text: "D、超过 3000 万元",
              },
            ],
          },
          {
            step: 5,
            title: "贵单位是否有尚未清偿的数额较大的债务？如有，主要是",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、银行贷款 ",
              },
              {
                id: 2,
                score: 3,
                text: "B、公司债券或企业债券",
              },
              {
                id: 3,
                score: 4,
                text: "C、通过担保公司等中介机构募集的借款",
              },
              {
                id: 4,
                score: 5,
                text: "D、民间借贷",
              },
              {
                id: 5,
                score: 6,
                text: "E、没有数额较大的债务",
              },
            ],
          },
          {
            step: 6,
            title: "对于金融产品投资工作，贵单位打算配置怎样的人员力量",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 1,
                text: "A、一名兼职人员（包括负责人自行决策） ",
              },
              {
                id: 2,
                score: 4,
                text: "B、一名专职人员",
              },
              {
                id: 3,
                score: 3,
                text: "C、多名兼职或专职人员，相互之间分工不明确",
              },
              {
                id: 4,
                score: 5,
                text: "D、多名兼职或专职人员，相互之间有明确分工",
              },
            ],
          },
          {
            step: 7,
            title: "贵单位所配置的负责金融产品投资工作的人员是否符合以下情况",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 5,
                text: "A、现在或此前曾从事金融、经济或财会等与金融产品投资相关的工作超过两年 ",
              },
              {
                id: 2,
                score: 4,
                text: "B、已取得金融、经济或财会等与金融产品投资相关专业学士以上学位",
              },
              {
                id: 3,
                score: 3,
                text: "C、取得证券从业资格、期货从业资格、注册会计师证书（CPA）或注册金融分析师证书 （CFA）中的一项及以上",
              },
              {
                id: 4,
                score: 1,
                text: "D、本单位所配置的人员不符合以上任何一项描述",
              },
            ],
          },
          {
            step: 8,
            title: "贵单位是否建立了金融产品投资相关的管理制度",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 1,
                text: "A、没有。因为要保证操作的灵活性 ",
              },
              {
                id: 2,
                score: 3,
                text: "B、已建立。包括了分工和授权的要求，但未包括投资风险控制的规则",
              },
              {
                id: 3,
                score: 5,
                text: "C、已建立。包括了分工与授权、风险控制等一系列与金融产品投资有关的规则",
              },
            ],
          },
          {
            step: 9,
            title: "贵单位的投资经验可以被概括为",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、有限：除银行活期账户和定期存款外，基本没有其他投资经验",
              },
              {
                id: 2,
                score: 4,
                text: "B、一般：除银行活期账户和定期存款外，购买过基金、保险等理财产品，但还需要进一步的指导",
              },
              {
                id: 3,
                score: 3,
                text: "C、丰富：本单位具有相当投资经验，参与过股票、基金等产品的交易，并倾向于自己 做出投资决策",
              },
              {
                id: 4,
                score: 5,
                text: "D、非常丰富：本单位对于投资非常有经验，参与过权证、期货或创业板等高风险产品 的交易",
              },
            ],
          },
          {
            step: 10,
            title:
              "有一位投资者一个月内做了 15 笔交易（同一品种买卖各一次算一笔），贵单位认为 这样的交易频率",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、太高了  ",
              },
              {
                id: 2,
                score: 4,
                text: "B、偏高",
              },
              {
                id: 3,
                score: 3,
                text: "C、正常",
              },
              {
                id: 4,
                score: 5,
                text: "D、偏低",
              },
            ],
          },
          {
            step: 11,
            title:
              "过去一年时间内，您购买的不同金融产品（含同一类型的不同金融产品）的数量是",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、5 个以下 ",
              },
              {
                id: 2,
                score: 4,
                text: "B、6 至 10 个  ",
              },
              {
                id: 3,
                score: 3,
                text: "C、11 至 15 个 ",
              },
              {
                id: 4,
                score: 5,
                text: "D、16 个以上",
              },
            ],
          },
          {
            step: 12,
            title: "以下金融产品，贵单位投资经验在两年以上的有",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、银行存款 ",
              },
              {
                id: 2,
                score: 3,
                text: "B、债券、货币市场基金、债券型基金或其它固定收益类产品",
              },
              {
                id: 3,
                score: 4,
                text: "C、股票、混合型基金、偏股型基金、股票型基金等权益类投资品种 ",
              },
              {
                id: 4,
                score: 5,
                text: "D、期货、融资融券",
              },
              {
                id: 5,
                score: 6,
                text: "E、复杂金融产品或其他产品",
              },
            ],
          },
          {
            step: 13,
            title:
              "如果贵单位曾经从事过金融产品投资，在交易较为活跃的月份，平均月交易额大概 是多少",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、100 万元以内",
              },
              {
                id: 2,
                score: 4,
                text: "B、100 万元-300 万元  ",
              },
              {
                id: 3,
                score: 5,
                text: "C、300 万元-1000 万元 ",
              },
              {
                id: 4,
                score: 6,
                text: "D、1000 万元以上",
              },
              {
                id: 5,
                score: 1,
                text: "D、从未投资过金融产品",
              },
            ],
          },
          {
            step: 14,
            title: "贵单位用于证券投资的大部分资金不会用作其它用途的时间段为",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 1,
                text: "A、短期——0 到 1 年",
              },
              {
                id: 2,
                score: 3,
                text: "B、中期——1 到 5 年 ",
              },
              {
                id: 3,
                score: 5,
                text: "C、长期——5 年以上",
              },
            ],
          },
          {
            step: 15,
            title: "贵单位进行投资时的首要目标是",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、资产保值，我不愿意承担任何投资风险 ",
              },
              {
                id: 2,
                score: 4,
                text: "B、尽可能保证本金安全，不在乎收益率比较低",
              },
              {
                id: 3,
                score: 3,
                text: "C、产生较多的收益，可以承担一定的投资风险",
              },
              {
                id: 4,
                score: 5,
                text: "D、实现资产大幅增长，愿意承担很大的投资风险",
              },
            ],
          },
          {
            step: 16,
            title: "贵单位打算重点投资于哪个种类的投资品种？",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 1,
                text: "A、债券、货币市场基金、债券基金等固定收益类投资品种 ",
              },
              {
                id: 2,
                score: 3,
                text: "B、股票、混合型基金、偏股型基金、股票型基金等权益类投资品种",
              },
              {
                id: 3,
                score: 4,
                text: "C、期货、融资融券 ",
              },
              {
                id: 4,
                score: 5,
                text: "D、复杂金融产品",
              },
              {
                id: 5,
                score: 6,
                text: "E、其他产品",
              },
            ],
          },
          {
            step: 17,
            title: "贵单位认为自己能承受的最大投资损失是多少",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 2,
                text: "A、10%以内  ",
              },
              {
                id: 2,
                score: 3,
                text: "B、10%-30% ",
              },
              {
                id: 3,
                score: 4,
                text: "C、30%-50% ",
              },
              {
                id: 4,
                score: 5,
                text: "D、超过 50%",
              },
            ],
          },
          {
            step: 18,
            title:
              "假设有两种不同的投资：投资 A 预期获得 5%的收益，有可能承担非常小的损失；投 资 B 预期获得 20%的收益，但有可能面临 25%甚至更高的亏损。您将您的投资资产分配为",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 1,
                text: "A、全部投资于 A ",
              },
              {
                id: 2,
                score: 3,
                text: "B、大部分投资于 A ",
              },
              {
                id: 3,
                score: 4,
                text: "C、两种投资各一半 ",
              },
              {
                id: 4,
                score: 5,
                text: "D、大部分投资于 B",
              },
              {
                id: 5,
                score: 6,
                text: "D、全部投资于 B",
              },
            ],
          },
          {
            step: 19,
            title: "贵单位参与金融产品投资的主要目的是什么",
            actives: "",
            listVal: [
              {
                id: 1,
                score: 5,
                text: "A、闲置资金保值增值 ",
              },
              {
                id: 2,
                score: 4,
                text: "B、获取主营业务以外的投资收益",
              },
              {
                id: 3,
                score: 2,
                text: "C、现货套期保值、对冲主营业务风险",
              },
              {
                id: 4,
                score: 3,
                text: "D、减持已持有的股票",
              },
            ],
          },
        ];
        this.userActive_m1_Arr_step = 1;
        this.userActive_m1_Arr = arr2;
      }

      if (this.steps_m2_radio == 0) {
        this.$refs["userActive_m1_form_ruleForm"].validate((valid) => {
          if (valid) {
            this.active++;
          } else {
            return false;
          }
        });
      } else {
        this.$refs["userActive_m1_institution_form_ruleForm"].validate(
          (valid) => {
            if (valid) {
              this.active++;
            } else {
              return false;
            }
          }
        );
      }
    },
    user_ajax_m2() {
      let userform = this.userActive_m1_institution_form;
    },
    activeFun() {
      // if (this.active++ > 2) this.active = 0;
      if (this.active == 0) {
        this.user_ajax_m1();
      } else if (this.active == 1) {
      } else if (this.active == 2) {
        this.active = 0;
        if (this.formData_v != "") {
         
          this.$store
            .dispatch("user/asset_file", this.formData_v)
            .then((e) => {
              this.steps_m2_radio = 0;
              this.wjbox = false;
              this.active = 0;
              this.$message("提交成功，请等待管理员审核！");
              this.formData_v = "";
              this.$store
                .dispatch("user/user_info", {})
                .then((e) => {
                  //console.log(e);
                })
                .catch(() => {});
            })
            .catch(() => {});
        } else {
          this.$message.error("文件不能为空");
        }
      }
    },
    steps_m2_radio_change(e) {
      if (e == 0) {
        this.resetForm("userActive_m1_form_ruleForm");
      } else {
        this.resetForm("userActive_m1_institution_form_ruleForm");
      }
      //
      //
    },
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },
    routerFun(url) {
      if (this.token) {
        if (this.is_active == 3) {
          this.$router.push(url);
        } else if (this.is_active == 1) {
          this.$message("后台正在审核中。。");
        } else {
          this.wjbox = true;
        }
      } else {
        this.$message("请先登录！");
      }
    },
    handleClose(done) {
      done();
      this.wjbox = false;
      this.steps_m2_radio = 0;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
<style>
.wjboxwidth {
  width: 490px;
}
</style>
<style  lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 12px 10px;
  }
  .el-radio__label {
    font-size: 14px;
    display: inline-block;
    white-space: normal;
    line-height: 16px;
    vertical-align: middle;
  }
  .custom-radio-red {
    .el-radio__input.is-checked + .el-radio__label {
      color: #e80101;
    }
    .el-radio__inner {
      border-color: #e80101; /* 默认边框颜色 */
    }
    .el-radio__inner::after {
      background-color: #e80101; /* 默认选中状态的背景颜色 */
    }
    .is-checked .el-radio__inner {
      border-color: #e80101; /* 选中状态的边框颜色 */
    }
    .is-checked .el-radio__inner::after {
      background-color: #ffffff; /* 选中状态的背景颜色 */
    }
    .el-radio__input.is-checked .el-radio__inner {
      background: #e80101; /*圆圈里面背景色*/
    }
  }
  .el-upload-dragger {
    width: 365px;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 975px) {
  .goodsteam {
    .goodsteam_box {
      .goodsteam_box_text {
        width: auto;
      }
      .goodsteam_box_money {
        width: auto !important;
        padding: 30px 0px !important;
        .goodsteam_m {
          height: 400px !important;
          background: none !important;
          .ms {
            float: none !important;
            width: auto !important;
          }
        }
      }
      .goodsteam_news {
        .goodsteam_news_m7 {
          width: auto !important;
          .v1 {
            .v3 {
              width: 35% !important;
            }
          }
        }
      }
      .goodsteam_news_m7 {
        .v1 {
          img {
            height: 200px !important;
          }
        }
      }
      .goodsteam_m8 {
        .m {
          width: 100% !important;
        }
      }
      .goodsteam_m9 {
        .m {
          width: 100% !important;
          .v {
            display: block !important;
            overflow: hidden;
            margin-top: 40px;
            .v1 {
              width: 100% !important;
              height: 440px !important;
              padding: 20px !important;
            }
            .v1s {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}

.wjbox {
  .steps_box {
    box-sizing: border-box;
    padding: 20px 0px 0px 00px;
    .steps_m3 {
      text-align: center;
    }
    .steps_m2 {
      overflow: hidden;
      font-size: 16px;
      color: #1a1a1a;
      text-align: center;
      font-weight: bold;
      margin-top: 0px;
      span {
        margin-right: 10px;
      }
      .radioClass {
        display: block;
        width: 100%;
        margin: 6px 0px;
        text-align: left;
        //  color: red;

        padding: 14px 8px 0px 8px;
        vertical-align: middle;
      }

      .pw {
        margin-bottom: 6px;
      }
    }
    .steps_m1 {
      width: 420px;
      margin: 0 auto;
      box-sizing: border-box;
      padding-right: 35px;
      .codeBtn {
        background: transparent;
        border: none;
        color: #fdc83a;
      }
    }
  }
  .dialog-title {
    height: 60px;
    line-height: 45px;
    padding: 0px 30px;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    color: #000;
  }

  .con {
    width: auto;

    padding: 0px 0px;
    overflow-y: auto;
    .ms1 {
      .ti {
        overflow: hidden;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #e80101;
      }
      .pt {
        overflow: hidden;
        font-size: 14px;
        color: #999;
        span {
          color: #e80101;
        }
      }
    }
  }
}
.goodsteam {
  width: 100%;
  margin-top: -30px;
  overflow: hidden;

  .goodsteam_box {
    background: #fafafa;

    .goodsteam_m9 {
      overflow: hidden;
      padding: 100px 0px;
      background: #fff;
      .m {
        width: 1200px;
        height: auto;
        margin: 0px auto;
        .p1s {
          overflow: hidden;
          text-align: center;
          font-size: 40px;
        }
        .p2s {
          overflow: hidden;
          text-align: center;
          font-size: 15px;
          color: #999;
          margin-top: 60px;
        }
        .v {
          display: flex;
          overflow: hidden;
          margin-top: 40px;
          p {
            margin-bottom: 10px;
          }

          .v1 {
            width: 380px;
            height: 480px;
            padding: 30px 60px;
            background: #fafafa;
            border-radius: 10px;
            .p1 {
              font-size: 72px;
              font-weight: bold;
              color: #f4aaaf;
            }

            .p1-1 {
              font-size: 24px;
              color: #333;
              margin-top: 20px;
              margin-bottom: 10px;
            }
            .p2 {
              overflow: hidden;
              font-size: 15px;
              color: #333;
              line-height: 20px;
            }
            .p2-2 {
              overflow: hidden;
              margin: 50px 0px 10px 0px;

              a {
                display: inline-block;
                background: #fff;
                border: 1px solid #7d7d7d;
                font-size: 16px;
                color: #000;
                width: auto;
                height: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                padding: 0px 20px;
                &:hover {
                  background: #e60012;
                  color: #fff;
                }
              }
            }

            .p3 {
              overflow: hidden;
              font-size: 15px;
              color: #999999;
              line-height: 26px;
            }
            .v5 {
              display: flex;
              img {
                width: 80px;
                height: 80px;
              }
              .r {
                flex: 1;
                text-align: right;
                .p4 {
                  font-size: 15px;
                  color: #999;
                  line-height: 26px;
                }
                .p5 {
                  font-size: 15px;
                  color: #333;
                  line-height: 26px;
                  i {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
          .v1s {
            margin: 0px 30px;
          }
        }
      }
    }
    .goodsteam_m8 {
      width: 100%;
      height: 530px;
      background: url(../assets/i12.jpg) no-repeat center center;
      overflow: hidden;
      .m {
        width: 1200px;
        height: auto;
        margin: 0px auto;
        .p1 {
          overflow: hidden;
          text-align: center;
          font-size: 40px;
          color: #000;
          margin-top: 140px;
        }
        .p2 {
          overflow: hidden;
          text-align: center;
          font-size: 20px;
          color: #000;
          margin-top: 30px;
        }
        .p3 {
          overflow: hidden;
          text-align: center;
          margin-top: 100px;
          a {
            display: inline-block;
            background: #fff;
            border: 1px solid #7d7d7d;
            font-size: 18px;
            color: #000;
            width: 140px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border-radius: 10px;
            &:hover {
              background: #e60012;
              color: #fff;
            }
          }
        }
      }
    }

    .goodsteam_news {
      background: white;
      padding: 78px 0px;
      .goodsteam_news_m7 {
        width: 1200px;
        margin: 0 auto;
        .p1 {
          text-align: center;
          overflow: hidden;
          font-size: 40px;
          color: #000;
        }
        .p2 {
          text-align: center;
          overflow: hidden;
          font-size: 18px;
          color: #333;
          margin-top: 50px;
          line-height: 30px;
        }
        .v1 {
          margin-top: 40px;
          .v2 {
            width: 30%;
            float: left;
            overflow: hidden;
          }
          .v3 {
            width: 38%;
            box-sizing: border-box;
            margin: 0 10px;
            float: left;
            overflow: hidden;
          }
          .v4 {
            width: 30%;
            float: left;
            overflow: hidden;
          }
          img {
            width: 100%;
            height: 356px;
            transition: 0.5s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .goodsteam_box_money {
      overflow: hidden;
      padding: 90px 0px;
      width: 1200px;
      margin: 0 auto;
      .goodsteam_m {
        height: 498px;
        background: url(../assets/i8.jpg) no-repeat left center;
        transition: 0.8s;

        .ms {
          width: 520px;
          height: 360px;
          background: #40b1fe;
          float: right;
          margin-top: 60px;
          padding: 30px;
          .p1 {
            overflow: hidden;
            font-size: 36px;
            font-weight: bold;
            color: #fff;
          }
          ul {
            overflow: hidden;
            margin-top: 10px;
            li {
              overflow: hidden;
              font-size: 16px;
              line-height: 40px;
              height: 40px;
              text-overflow: ellipsis;
              white-space: nowrap;
              a {
                color: #fff;
              }
            }
            :hover {
            }
          }
          .p2 {
            overflow: hidden;
            font-size: 30px;
            font-weight: bold;
            color: #fff;
            text-indent: 15px;
            a {
              color: #fff;
            }
          }
          .p3 {
            overflow: hidden;
            font-size: 18px;
            color: #fff;
            text-indent: 15px;
            margin-top: 15px;
            a {
              color: #fff;
            }
          }
        }
      }
      > :hover {
        background: url(../assets/i8.jpg) no-repeat 50px center;
      }
    }
    .goodsteam_box_bg {
      width: 100%;
      height: 539px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(../assets/0c149c3d5eaa7f4dc0a5d9479a79bf3.png);
      .goodsteam_box_text {
        display: flex;
        width: 1200px;
        height: auto;
        margin: 0px auto 0px auto;
        box-sizing: border-box;
        padding-top: 200px;
        p {
          align-self: center;
          font-size: 22px;
          color: #000;
          line-height: 44px;
          padding-top: 40px;
          padding-left: 10px;
        }
      }
    }
  }
}
</style> 