var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_vm._m(0),_c('div',{staticClass:"about_m6 animated",attrs:{"data-ani":"fadeInUp"}},[_c('div',{staticClass:"m"},[_c('div',{staticClass:"l"},[_vm._m(1),_c('p',{staticClass:"p2",staticStyle:{"margin-bottom":"25px","margin-top":"10px"}},[_vm._v(" 重庆大凡系西南地区以资产管理为主的综合性专业量化投资公司之一，于2010年初步组建，2014年正式成立，注册资金1千万,实缴资本1千万。 ")]),_c('p',{staticClass:"p3",staticStyle:{"margin-bottom":"50px"}},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.routerFun('/company')}}},[_vm._v("查看更多")])])]),_vm._m(2)])]),_c('div',{staticClass:"about_m7 animated",attrs:{"data-ani":"fadeInUp"}},[_c('div',{staticClass:"m"},[_c('div',{staticClass:"ms"},[_c('p',{staticClass:"p1"},[_vm._v("大凡团队")]),_vm._m(3),_c('p',{staticClass:"p3"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.routerFun('/company')}}},[_vm._v("查看更多")])])])])]),_c('div',{staticClass:"about_m8 animated",attrs:{"data-ani":"fadeInUp"}},[_c('div',{staticClass:"m"},[_c('div',{staticClass:"l"},[_c('div',{staticClass:"ms",on:{"click":function($event){return _vm.routerFun('/news')}}},[_c('p',{staticClass:"p1"},[_vm._v("大凡新闻")]),_vm._m(4),_vm._m(5)])]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_m5"},[_c('div',{staticClass:"m"},[_c('p',{staticStyle:{"color":"#fff"}},[_vm._v(" 专注量化CTA策略，使用机器学习处理海量数据、回归分析建立交易模型、"),_c('br'),_vm._v("分析期货市场交易走势，连续多年取得稳健收益 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p1"},[_vm._v("公司简介"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ms"},[_c('ul',[_c('li',[_c('div',{staticClass:"i"},[_c('img',{attrs:{"src":require("../assets/i22.png")}})]),_c('div',{staticClass:"r"},[_c('p',{staticClass:"p1"},[_vm._v("多次荣获行业相关奖项")]),_c('p',{staticClass:"p2"},[_vm._v("公司专注二级市场量化投资")])])]),_c('li',[_c('div',{staticClass:"i"},[_c('img',{attrs:{"src":require("../assets/i23.png")}})]),_c('div',{staticClass:"r"},[_c('p',{staticClass:"p1"},[_vm._v("大凡理念")]),_c('p',{staticClass:"p2"},[_vm._v("大凡人遵循“因天下之力、")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p2"},[_vm._v(" 信号识别、机器学习领域专家团队，汇聚了有着丰富经验的证券、银行、期货、基金"),_c('br'),_vm._v("领域人才，具备完善的中后台支持体系， 在同行业中显露 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("·")]),_vm._v("【大凡荣誉】大凡投资荣获第五届Wind最强私募榜单多项大奖")])]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("·")]),_vm._v("投资理财明白纸 读完十句话，明明白白投资")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p3"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("查看更多")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"r"},[_c('img',{attrs:{"src":require("../assets/8596df072cf74184cc1b9d923e36c958.jpg")}})])
}]

export { render, staticRenderFns }