<template>
  <div id="app">
    <keep-alive><router-view name="hender"  ></router-view></keep-alive>
    <router-view />
    <router-view name="footer"></router-view>

  </div>

  <!-- <div id="app">
    
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
export default {
  props:['backgroundColor'],
  name: "App",
  components: {
    HelloWorld,
  },
  mounted() {
 
  
  },
};
</script>

<style>
body，html ul {
  margin: 0;
  padding: 0;

}
ul li {
  list-style: none;
}
a {
  text-decoration: none !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}
</style>
