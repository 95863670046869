<template>
  <div class="teaching">
    <div class="teaching_m1">
      <div class="m flex">
        <p>认清自己，才是投资成功的决定性因素</p>
      </div>
    </div>
    <div class="teaching_m2 animated" data-ani="fadeInUp">
      <div class="m mt">
        <div class="t">会投资、会阅读</div>
      </div>
      <div class="m mbg">
        <div class="ms">
          <p class="p1">基金学习</p>
          <ul>
            <li @click="routerFun('/teamNews')">
              <a href="javascript:void(0)">
                <span>·</span>心理账户：基金投资中最难逃的“投资魔咒”
              </a>
            </li>
            <li @click="routerFun('/teamNews')">
              <a href="javascript:void(0)">
                <span>·</span>想做量化研究，需要哪些知识？
              </a>
            </li>
            <li @click="routerFun('/teamNews')">
              <a href="javascript:void(0)">
                <span>·</span>多元化不是投资的终极答案
              </a>
            </li>
          </ul>
          <p class="p2">...</p>
          <p class="p3">
            <a href="javascript:void(0)" @click="routerFun('/teamNews')"
              >查看更多 <i class="icon iconfont icon-jiantou_xiangyou_o"></i
            ></a>
          </p>
        </div>
      </div>
    </div>
    <div class="teaching_m3 animated" data-ani="fadeInUp">
      <div class="m mbg">
        <div class="ms">
          <p class="p1">行业动态</p>
          <ul>
            <li @click="routerFun('/teamNews')">
              <a href="javascript:void(0)">
                <span>·</span>美联储开启疫情后的第一轮加息……
              </a>
            </li>
            <li @click="routerFun('/teamNews')">
              <a href="javascript:void(0)">
                <span>·</span>房地产调控政策出现变化；乌克兰危机可能扩大化 |
                大凡宏观
              </a>
            </li>
            <li @click="routerFun('/teamNews')">
              <a href="javascript:void(0)">
                <span>·</span>大凡宏观 | 国内外市场核心逻辑解析（第六期）
              </a>
            </li>
          </ul>
          <p class="p2">...</p>
          <p class="p3">
            <a href="javascript:void(0) " @click="routerFun('/teamNews')"
              >查看更多 <i class="icon iconfont icon-jiantou_xiangyou_o"></i
            ></a>
          </p>
        </div>
      </div>
    </div>
    <div class="teaching_m4 animated" data-ani="fadeInUp">
      <a href="javascript:void(0)" @click="routerFun('/teamNews')">
        <div class="m">
          <p class="p1">投资者教育</p>
          <p class="p2">好公司持续价值创造能力，大凡投教 陪伴长期投资之旅</p>
          <div class="v1 flex">
            <div class="v2">
              <img src="../assets/i16.jpg" />
              <p>政策法规</p>
            </div>
            <div class="v3">
              <img src="../assets/i17.jpg" />
              <p>投资者保护</p>
            </div>
            <div class="v4">
              <img src="../assets/i18.jpg" />
              <p>价值投资</p>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
  <script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      // this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },
    routerFun(url) {
      this.$router.push(url);
    },
    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 975px) {
  .teaching {
    .teaching_m2 {
      .m {
        width: 100% !important;
      }
    }
    .teaching_m4 {
      padding: 0px 0px  40px 0px!important;
      a {
        .m {
          width: 100% !important;
          .v1 {
            img {
              height: 180px !important;
            }
          }
        }
      }
    }
  }
}
.teaching {
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .teaching_m4 {
    width: 100%;
    overflow: hidden;
    background: #fff;
    padding: 90px 0px;
    a {
      outline: none;
      border: 0;
      text-decoration: none;
      .m {
        width: 1200px;
        height: auto;
        margin: 0px auto;
      }
      .p1 {
        text-align: center;
        overflow: hidden;
        font-size: 40px;
        color: #000;
      }
      .p2 {
        text-align: center;
        overflow: hidden;
        font-size: 18px;
        color: #333;
        margin-top: 50px;
        line-height: 30px;
      }
      .v1 {
        margin-top: 40px;
        display: flex;
        .v2 {
          width: 324px;
          overflow: hidden;
        }
        .v3 {
          width: 510px;
          margin: 0px 21px;
          overflow: hidden;
        }
        .v4 {
          width: 324px;
          overflow: hidden;
        }
        img {
          width: 100%;
          height: 356px;
          transition: 0.5s;
          &:hover {
            transform: scale(1.1);
          }
        }
        p {
          overflow: hidden;
          text-align: center;
          font-size: 24px;
          color: #333;
          padding-top: 30px;
        }
      }
    }
  }
  .teaching_m3 {
    width: 100%;
    overflow: hidden;
    padding: 90px 0px;
    .mbg {
      height: 498px;
      background: url(../assets/i15.jpg) no-repeat right center;
      transition: 0.8s;
      width: 1200px;

      margin: 0px auto;
      .ms {
        width: 520px;
        height: 380px;
        background: #6acfec;
        float: left;
        margin-top: 50px;
        padding: 60px 50px;
        .p1 {
          overflow: hidden;
          font-size: 36px;
          font-weight: bold;
          color: #333;
        }
        ul {
          overflow: hidden;
          margin-top: 20px;
          li {
            overflow: hidden;
            font-size: 16px;
            line-height: 40px;
            height: 40px;
            text-overflow: ellipsis;
            white-space: nowrap;
            a {
              color: #333;
              span {
                font-size: 30px;
                font-weight: bold;
                position: relative;
                top: 5px;
                margin-right: 10px;
              }
            }
          }
        }
        .p2 {
          overflow: hidden;
          font-size: 30px;
          font-weight: bold;
          color: #333;
          text-indent: 15px;
        }
        .p3 {
          overflow: hidden;
          font-size: 18px;
          color: #333;
          text-indent: 15px;
          margin-top: 15px;
          a {
            color: #333;
            i {
              font-size: 22px;
            }
          }
        }
      }
      &:hover {
        background: url(../assets/i15.jpg) no-repeat 448px center;
      }
    }
  }
  .teaching_m2 {
    width: 100%;
    overflow: hidden;
    padding: 90px 0px;
    .m {
      width: 1200px;
      height: auto;
      margin: 0px auto;
    }
    .mt {
      .t {
        overflow: hidden;
        text-align: center;
        font-size: 40px;
        color: #333;
        padding-bottom: 80px;
      }
    }
    .mbg {
      height: 498px;
      background: url(../assets/i14.jpg) no-repeat left center;
      transition: 0.8s;
      .ms {
        width: 520px;
        height: 380px;
        background: #ecc56a;
        float: right;
        margin-top: 50px;
        padding: 50px 50px;
        .p1 {
          overflow: hidden;
          font-size: 36px;
          font-weight: bold;
          color: #333;
        }
        ul {
          overflow: hidden;
          margin-top: 20px;
          li {
            overflow: hidden;
            font-size: 16px;
            line-height: 40px;
            height: 40px;
            text-overflow: ellipsis;
            white-space: nowrap;
            list-style: none;
            a {
              color: #333;
              span {
                font-size: 30px;
                font-weight: bold;
                position: relative;
                top: 5px;
                margin-right: 10px;
              }
            }
          }
        }
        .p2 {
          overflow: hidden;
          font-size: 30px;
          font-weight: bold;
          color: #333;
          text-indent: 15px;
        }
        .p3 {
          overflow: hidden;
          font-size: 18px;
          color: #333;
          text-indent: 15px;
          margin-top: 15px;
          a {
            color: #333;
          }
        }
      }
      &:hover {
        background: url(../assets/i14.jpg) no-repeat 50px center;
      }
    }
  }
  .teaching_m1 {
    background-image: url(../assets/i13.jpg);
    width: 100%;
    height: 589px;
    background-repeat: no-repeat;
    background-position: center center;
    .m {
      height: 100%;
      display: flex;
      width: 1200px;

      margin: 0px auto;
      p {
        align-self: center;
        font-size: 24px;
        color: #000;
        line-height: 44px;
        padding-top: 120px;
      }
    }
  }
}
</style> 