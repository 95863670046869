import request from '@/utils/request'

export function fund_corrcoef(data) {
    return request({
        url: '/fund/fund_corrcoef/',
        method: 'post',
        data
    })

}
export function fund_data(data) {
    return request({
        url: '/fund/fund_data/',
        method: 'post',
        data
    })

}
export function fund_datanew(data) {
    return request({
        url: '/fund/fund_datanew/',
        method: 'post',
        data
    })

}
export function fund_details(data) {
    return request({
        url: '/fund/fund_details/',
        method: 'post',
        data
    })

}
export function fund_list(data) {
    return request({
        url: '/fund/fund_list/',
        method: 'post',
        data
    })

}
export function companydetails(data) {
    return request({
        url: '/fund/companydetails/',
        method: 'post',
        data
    })

}
export function fund_raw(data) {
    return request({
        url: '/fund/fund_raw/',
        method: 'post',
        data
    })

}
export function login(data) {
    return request({
        url: '/authorizations/',
        method: 'post',
        data
    })
}
export function weekly_data(data) {
    return request({
        url: '/fund/weekly_data/',
        method: 'post',
        data
    })
}
export function create_user(data) {
    return request({
        url: '/create_user/',
        method: 'post',
        data
    })
}
export function productdetails(data) {
    return request({
        url: '/fund/productdetails/',
        method: 'post',
        data
    })
}
export function user_info(data) {
    return request({
        url: '/user_info/',
        method: 'post',
        data
    })
}

export function edit_user(data) {
    return request({
        url: '/edit_user/',
        method: 'put',
        data
    })
}
export function get_news(data) {
    return request({
        url: '/news/get_news/',
        method: 'get',
        data
    })
}
export function send_msg(data) {
    return request({
        url: '/send_msg/',
        method: 'post',
        data
    })
}
export function query_user(data) {
    return request({
        url: '/query_user/',
        method: 'post',
        data
    })
}


export function asset_file(data) {
    return request({
        url: '/fund/asset_file/',
        method: 'put',
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}