<template>
  <div
    class="scroll-content"
    @scroll="onScroll"
    :style="
      'overflow-x: hidden; overflow-y: auto; height:' + contentStyleObj.height
    "
  >
    <div class="box">
      <div class="box-nav" style="text-align: left; position: relative;">
        <h2 style="line-height: 30px">星月山夸克进取型</h2>
        <img src="../assets/a29kj-p8ab5.png" alt="" style="position: absolute;
    top: -4px;
    left: 240px;">
        <el-tag type="success" size="mini">私募</el-tag>
        <el-tag type="info" size="mini" style="margin-left: 10px"
          >多资产策略</el-tag
        >
        <el-tag type="warning" size="mini" style="margin-left: 10px"
          >宏观策略</el-tag
        >
        <el-tag type="danger" size="mini" style="margin-left: 10px">
          R5-高风险</el-tag
        >
      </div>
      <div class="box-title" style="margin: 14px 0px">
        <el-row :gutter="0">
          <el-col :span="7"
            ><div class="box-title-text">
              单位净值 ：
              <span style="font-size: 22px; font-weight: 600"> 1.5206 </span>
              (2024-08-02)
            </div></el-col
          >
          <el-col :span="7"
            ><div class="box-title-text">
              今年来收益 <i class="el-icon-question" style=" cursor: pointer;" ></i>
              <span class="box-title-text-span"> 52.81% </span>
              (截止至2024-08-02)
            </div></el-col
          >
          <el-col :span="7"
            ><div class="box-title-text">
              成立来收益(1.9年) <i class="el-icon-question" style=" cursor: pointer;"></i> ：
              <span class="box-title-text-span"> 52.06% </span>
              (2024-08-02)
            </div></el-col
          >
        </el-row>
      </div>
      <div class="box-li">
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="box-li-text">
              <div>
                累计净值： <i class="el-icon-question" style=" cursor: pointer;"></i>
                <span
                  class="box-li-text-span"
                  style="font-size: 18px; font-weight: 600"
                  >1.5206</span
                >
              </div>
              <div>基金状态 <span class="box-li-text-span">正在运作</span></div>
              <div>备案编号： <span class="box-li-text-span">SXD417</span></div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="box-li-text">
              <div>
                成立以来年化： <i class="el-icon-question"></i>
                <span class="box-li-text-span" style="color: #e1322d"
                  >24.44%</span
                >
              </div>
              <div>
                成立日期 <span class="box-li-text-span">2022-09-02</span>
              </div>
              <div>
                公司管理规模 <span class="box-li-text-span">0~5亿</span>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="box-li-text">
              <div>
                成立以来回撤： <span class="box-li-text-span">24.59%</span>
              </div>
              <div>
                管理人： <span class="box-li-text-span">前海夸克资产</span>
              </div>
              <div>
                近一年收益：
                <span class="box-li-text-span" style="color: #e1322d"
                  >26.40%</span
                >
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="box-li-text">
              <div>
                成立以来夏普： <i class="el-icon-question"></i>
                <span class="box-li-text-span">0.92</span>
              </div>
              <div>基金经理： <span class="box-li-text-span">王升治</span></div>
              <div>
                近一年收益排名:
                <span class="box-li-text-span"
                  ><span style="color: #e1322d">1</span>/30</span
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-show="boxshow" class="boxshow">
      <div style="padding-left: 28px; position: relative">
        <h3 style="    margin: 16px 0px;">星月山夸克进取型</h3>
        <img
          style="position: absolute; left: 200px; top: -6px"
          src="../assets/a29kj-p8ab5.png"
          alt=""
        />
      </div>
      <el-tabs class="customer-tab2" @tab-click="jump" v-model="tabName">
        <el-tab-pane
          v-for="(tab, index) in tabs"
          :name="tab.refName"
          :key="index"
          :label="tab.name"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <el-tabs class="customer-tab" @tab-click="jump" v-model="tabName">
      <el-tab-pane
        v-for="(tab, index) in tabs"
        :name="tab.refName"
        :key="index"
        :label="tab.name"
      ></el-tab-pane>
    </el-tabs>
    <div>
      <!-- 用户管理 -->
      <div :ref="tabs[0].refName" class="scroll-item">
        <!-- <div class="line-name">
          <h2>{{ tabs[0].name }}</h2>
        </div> -->
        <div style="box-sizing: border-box; padding: 20px  10px; background: white">
          <el-tabs class="customer-tab3">
            <el-tab-pane label="收益走势图">
              <EchartsChart />
            </el-tab-pane>
            <el-tab-pane label="历史净值/分红">
              <EchartsChart_table/>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
     
      <div :ref="tabs[1].refName" class="scroll-item">
     
        <div style="box-sizing: border-box; padding: 10px; background: white">
          <ReturnRiskindicators/>
        </div>
      </div>
      <!-- 角色管理 -->
      <div
        :ref="tabs[2].refName"
        class="scroll-item"
        style="padding-top: 1rem; top: 5px"
      >
     
      <div style="box-sizing: border-box; padding: 10px; background: white">
          <IntervalReturns/>
        </div>
      </div>
      <!-- 角色管理2 -->
      <div
        :ref="tabs[3].refName"
        class="scroll-item"
        style="padding-top: 1rem; top: 5px"
      >
      <div style="box-sizing: border-box; padding: 10px; background: white">
          <RatesElements/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EchartsChart from "./tab/echarts.vue";
import EchartsChart_table from "./tab/EchartsChart_table.vue";
import ReturnRiskindicators from "./tab/ReturnRiskindicators.vue";
import IntervalReturns from "./tab/IntervalReturns.vue";
import RatesElements from "./tab/RatesElements.vue";
export default {
  name: "index",
  props: {},
  components: { EchartsChart,EchartsChart_table,ReturnRiskindicators,IntervalReturns,RatesElements },
  data() {
    return {
      boxshow: false,
      tabIndex: "0",
      contentStyleObj: {
        height: "100px",
      },
      tabName: "setOneRef",
      tabs: [
        {
          name: "收益走势图",
          refName: "setOneRef",
        },
        {
          name: "收益风险指标",
          refName: "setTwoRef",
        },
        {
          name: "区间收益",
          refName: "setThreeRef",
        },
        {
          name: "费率&要素",
          refName: "setFourRef",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  },
  methods: {
    // tab click
    jump(tab, event) {
      let target = document.querySelector(".scroll-content");
      let scrollItems = document.querySelectorAll(".scroll-item");
      // 判断滚动条是否滚动到底部
      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.tabIndex = tab.index.toString();
      }
      let totalY = scrollItems[tab.index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
      let distance = document.querySelector(".scroll-content").scrollTop; // 滚动条距离滚动区域顶部的距离
      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
      // 计算每一小段的距离
      let step = totalY / 50;
      if (totalY > distance) {
        smoothDown(document.querySelector(".scroll-content"));
      } else {
        let newTotal = distance - totalY;
        step = newTotal / 50;
        smoothUp(document.querySelector(".scroll-content"));
      }

      // 参数element为滚动区域
      function smoothDown(element) {
        if (distance < totalY) {
          distance += step;
          element.scrollTop = distance;
          setTimeout(smoothDown.bind(this, element), 10);
        } else {
          element.scrollTop = totalY;
        }
      }

      // 参数element为滚动区域
      function smoothUp(element) {
        if (distance > totalY) {
          distance -= step;
          element.scrollTop = distance;
          setTimeout(smoothUp.bind(this, element), 10);
        } else {
          element.scrollTop = totalY;
        }
      }
    },
    // 滚动条滚动
    onScroll(e) {
      let scrollItems = document.querySelectorAll(".scroll-item");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        console.log(e.target.scrollTop);

        //boxshow
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop - 400;
        if (judge) {
          this.tabIndex = i.toString();
          // 找对应的tab-name值
          this.tabName = this.tabs[this.tabIndex].refName;
          break;
        }
        if (e.target.scrollTop > 340) {
          this.boxshow = true;
        } else {
          this.boxshow = false;
        }
      }
    },
    getHight() {
      this.contentStyleObj.height = window.innerHeight - 0 + "px";
    },
  },
};
</script> 
 <style lang="scss" >
.customer-tab.el-tabs {
  margin: 8px 0px;
  box-sizing: border-box;
  padding: 6px 0px 6px 30px;
  background: white;
  .el-tabs__header {
    margin: 0px;
    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__active-bar {
            background-color: rgba(225, 50, 45);
            height: 4px;
          }
          .el-tabs__item {
            color: black;
            font-weight: 400;
            font-size: 16px;
            &:hover {
              color: rgba(225, 50, 45);
              cursor: pointer;
              opacity: 1;
            }
          }

          .el-tabs__content {
            overflow: visible;
          }
          .el-tabs__item.is-active {
            color: rgba(225, 50, 45);
          }
        }
      }
    }
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
}
.customer-tab2.el-tabs {
  margin: 4px 0px;
  box-sizing: border-box;
  padding: 0px 0px 6px 30px;

  .el-tabs__header {
    margin: 0px;
    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__active-bar {
            background-color: rgba(225, 50, 45);
            height: 4px;
          }
          .el-tabs__item {
            color: black;
            font-weight: 400;
            font-size: 16px;
            &:hover {
              color: rgba(225, 50, 45);
              cursor: pointer;
              opacity: 1;
            }
          }

          .el-tabs__content {
            overflow: visible;
          }
          .el-tabs__item.is-active {
            color: rgba(225, 50, 45);
          }
        }
      }
    }
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
}
.customer-tab3.el-tabs {
  margin: 3px 0px;
  box-sizing: border-box;
  padding: 0px 0px 6px 30px;

  .el-tabs__header {
    margin: 0px;
    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__active-bar {
            display: none;
          }
          .el-tabs__item {
            color: #0009;
            font-weight: 600;
            font-size: 16px;
            &:hover {
              color: rgba(225, 50, 45);
              cursor: pointer;
              opacity: 1;
            }
          }

          .el-tabs__content {
            overflow: visible;
          }
          .el-tabs__item.is-active {
            color: black;
          }
        }
      }
    }
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
}
</style>


 <style >
body,
html {
  margin: 0;
  padding: 0;
  background: #f7f8fc;
}

.box {
  box-sizing: border-box;
  padding: 20px 30px;
  background: url(../assets/aqlaj-jsweb.png) no-repeat;
  background-size: 100% 100%;
}
.boxshow {
  position: fixed;
  z-index: 99;
  background: url(../assets/aqlaj-jsweb.png) no-repeat;
  background-size: 100% 100%;
  width: calc(100vw - 18px);
  top: 0px;
  text-align: left;
}
.box-title-text {
  color: #000c;
  font-size: 14px;
  text-align: left;
  line-height: 30px;
}
.box-title-text-span {
  color: #e1322d;
  font-size: 18px;
}

.box-li-text {
  font-size: 14px;
  text-align: left;
  line-height: 30px;
}
.box-li-text-span {
  display: inline-block;
  float: right;
}
/* ::v-deep .customer-tab {
  width: 100%;
  height: 50px;
  background-color: #f5f7fa;
  padding: 4px;
}

::v-deep .el-tabs__content {
  overflow: visible;
}
::v-deep  .el-tabs__nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border: none;
}
::v-deep .el-tabs__item {
  width: 25%;
  font-size: 28px !important;
  text-align: center;
  border: none;
}
::v-deep .el-tabs__item.is-active {
  border-radius: 4px;
  background-color: #005bd9;
  color: #fff;
} */
</style> 