<template>
  <div class="news">
    <div class="news_m1">
      <div class="m flex">
        <p>
          专注量化CTA策略，使用机器学习处理海量数据、回归分析建立交易模型、<br />分析期货市场交易走势，连续多年取得稳健收益
        </p>
      </div>
    </div>
    <div class="news_m2 " >
      <div class="m">
        <div class="ts animated fadeInUp" data-animation="fadeInUp"></div>
        <ul class="u">
          <li data-animation="fadeInUp" class="animated fadeInUp"  @click="openNewWindow({ name: 'newclass' })">
            <a href="javascript:void(0)">
              <div class="im">
                <img
                  src="../assets/8596df072cf74184cc1b9d923e36c958.jpg"
                />
              </div>
              <div class="des">
                <p class="p1">2022年01月19日</p>
                <p class="p2">
                  【大凡荣誉】大凡投资荣获第五届Wind最强私募榜单多项大奖
                </p>
              </div>
            </a>
          </li>
          <li data-animation="fadeInUp" class="animated fadeInUp">
            <a href="javascript:void(0)">
              <div class="im">
                <img
                  src="../assets/8596df072cf74184cc1b9d923e36c958.jpg"
                />

              </div>
              <div class="des">
                <p class="p1">2021年06月15日</p>
                <p class="p2">投资理财明白纸 读完十句话，明明白白投资</p>
              </div>
            </a>
          </li>
         
        </ul>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  components: {},
  name: "news",
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
     // this.handleAnimate(); //初始化第一次加载时在视口内就执行动画
      addEventListener("scroll", this.handleScroll);
      addEventListener("scroll", this.handleAnimate);
    });
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll); //避免影响其他页面
    removeEventListener("scroll", this.handleAnimate);
  },
  methods: {
    handleScroll() {
      // 执行滚动到当前元素时想要执行的操作
      // console.log('Scrolled to .my-element');
    },
    openNewWindow(route) {
        const routeData = this.$router.resolve(route);
            window.open(routeData.href, '_blank');
    },
    handleAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      let dom = document.querySelectorAll(".animated");
      [].slice.call(dom).forEach((v) => {
        if (top + vh > v.offsetTop) {
          var delay = v.dataset.delay;
          if (delay) {
            setTimeout(() => {
              v.style.opacity = 1;
              v.classList.add(v.dataset.ani);
            }, delay);
          } else {
            v.style.opacity = 1;
            v.classList.add(v.dataset.ani);
          }
        } else {
          v.classList.remove(v.dataset.ani);
          v.style.opacity = 0;
        }
      });
    },
  },
};
</script>
  <style lang="scss" scoped>
@media (max-width: 975px) {
  .news {
  }
}
.news {
  background: #fafafa;
  width: 100%;
  margin-top: -30px;
  overflow: hidden;
  .news_m1 {
    width: 100%;
    height: 589px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../assets/i19.jpg);
    .m {
      width: 1200px;
      height: 100%;
      display: flex;
      margin: 0 auto;
      p {
        align-self: center;
        font-size: 24px;
        color: #000;
        line-height: 44px;
        padding-top: 40px;
      }
    }
  }
  .news_m2 {
    overflow: hidden;
    .m {
      width: 1200px;
      height: auto;
      margin: 0px auto;
    }
    .u {
      width: 100%;
      overflow: hidden;
      margin-top: 50px;
      li {
        float: left;
        margin-left: 10px;
        width: 570px;
        background: #fff;
        box-shadow: 0px 0px 10px #ddd;
        margin-top: 10px;
        margin-bottom: 40px;
        a {
          display: block;
          overflow: hidden;
          transition: 0.5s;
          .im {
            height: 260px;
            overflow: hidden;
            img{
                width: 100%;
                overflow: hidden;
                 transition: .5s;
            }
          }
          .des {
            padding: 30px 35px 30px;
            .p1 {
              overflow: hidden;
              font-size: 16px;
              color: #666;
            }
            .p2 {
              font-size: 18px;
              color: #333;
              line-height: 30px;
              height: 60px;
              overflow: hidden;
              margin-top: 10px;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
            }
          }
        }&:hover .im img{transform: scale(1.1);}
      }
    }
  }
}
</style> 