var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"teaching"},[_vm._m(0),_c('div',{staticClass:"teaching_m2 animated",attrs:{"data-ani":"fadeInUp"}},[_vm._m(1),_c('div',{staticClass:"m mbg"},[_c('div',{staticClass:"ms"},[_c('p',{staticClass:"p1"},[_vm._v("基金学习")]),_c('ul',[_c('li',{on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._m(2)]),_c('li',{on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._m(3)]),_c('li',{on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._m(4)])]),_c('p',{staticClass:"p2"},[_vm._v("...")]),_c('p',{staticClass:"p3"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._v("查看更多 "),_c('i',{staticClass:"icon iconfont icon-jiantou_xiangyou_o"})])])])])]),_c('div',{staticClass:"teaching_m3 animated",attrs:{"data-ani":"fadeInUp"}},[_c('div',{staticClass:"m mbg"},[_c('div',{staticClass:"ms"},[_c('p',{staticClass:"p1"},[_vm._v("行业动态")]),_c('ul',[_c('li',{on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._m(5)]),_c('li',{on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._m(6)]),_c('li',{on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._m(7)])]),_c('p',{staticClass:"p2"},[_vm._v("...")]),_c('p',{staticClass:"p3"},[_c('a',{attrs:{"href":"javascript:void(0) "},on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._v("查看更多 "),_c('i',{staticClass:"icon iconfont icon-jiantou_xiangyou_o"})])])])])]),_c('div',{staticClass:"teaching_m4 animated",attrs:{"data-ani":"fadeInUp"}},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.routerFun('/teamNews')}}},[_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"teaching_m1"},[_c('div',{staticClass:"m flex"},[_c('p',[_vm._v("认清自己，才是投资成功的决定性因素")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m mt"},[_c('div',{staticClass:"t"},[_vm._v("会投资、会阅读")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("·")]),_vm._v("心理账户：基金投资中最难逃的“投资魔咒” ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("·")]),_vm._v("想做量化研究，需要哪些知识？ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("·")]),_vm._v("多元化不是投资的终极答案 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("·")]),_vm._v("美联储开启疫情后的第一轮加息…… ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("·")]),_vm._v("房地产调控政策出现变化；乌克兰危机可能扩大化 | 大凡宏观 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("·")]),_vm._v("大凡宏观 | 国内外市场核心逻辑解析（第六期） ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m"},[_c('p',{staticClass:"p1"},[_vm._v("投资者教育")]),_c('p',{staticClass:"p2"},[_vm._v("好公司持续价值创造能力，大凡投教 陪伴长期投资之旅")]),_c('div',{staticClass:"v1 flex"},[_c('div',{staticClass:"v2"},[_c('img',{attrs:{"src":require("../assets/i16.jpg")}}),_c('p',[_vm._v("政策法规")])]),_c('div',{staticClass:"v3"},[_c('img',{attrs:{"src":require("../assets/i17.jpg")}}),_c('p',[_vm._v("投资者保护")])]),_c('div',{staticClass:"v4"},[_c('img',{attrs:{"src":require("../assets/i18.jpg")}}),_c('p',[_vm._v("价值投资")])])])])
}]

export { render, staticRenderFns }