<template>
  <div style="box-sizing: border-box; padding: 10px 20px">
    <div>
      <div style="text-align: left">
        <span style="font-size: 18px; font-weight: 700; line-height: 49px"
          >申赎细则</span
        >
      </div>
      <el-descriptions direction="horizontal" :column="4" border>
        <el-descriptions-item :span="2" label="起购金额">{{
          chartData.min_investment
        }}</el-descriptions-item>

        <el-descriptions-item :span="2" label="申购费">
          {{ chartData.subscription_fee }}</el-descriptions-item
        >

        <el-descriptions-item :span="2" label="赎回费">
          <span> {{ chartData.redemption_fee }}</span>
        </el-descriptions-item>

        <!-- <el-descriptions-item :span="4" label="申购开放日"
          >{{chartData.subscription_date}}</el-descriptions-item
        > -->
        <el-descriptions-item :span="4" label="申购开放日"
          ><span v-html="chartData.subscription_date"> </span
        ></el-descriptions-item>
        <el-descriptions-item :span="4" label="赎回开放日"
          ><span v-html="chartData.redemption_date"> </span></el-descriptions-item>

        <el-descriptions-item :span="4" label="管理费">
       
        <span v-html="chartData.manage_rate"> </span>
      
      </el-descriptions-item>

        <el-descriptions-item :span="4" label="托管费"
          >
          <span v-html="chartData.trustee_fee"> </span>
        </el-descriptions-item>

        <el-descriptions-item :span="4" label="运营服务费">
      <span v-html="chartData.operation_service_fee"> </span>  
      </el-descriptions-item>

        <el-descriptions-item :span="4" label="销售服务费">
  
          <span v-html="chartData.sales_service_fee"> </span>  
          </el-descriptions-item
        >
        <el-descriptions-item :span="4" label="业绩报酬">
      
          <span v-html="chartData.reward_rate"> </span>  
        </el-descriptions-item>
        <el-descriptions-item :span="4" label="其他费用">
          基金的证券、期货交易费用及账户开户费用；基金备案后与之相关的会计师费和律师费；基金的银行汇划费用；合同文件制作、印刷费用及使用电子合同相关费用；按照国家有关规定和本合同约定，可以在基金资产中列支的其他费用</el-descriptions-item
        >
      </el-descriptions>
    </div>
    <div style="margin: 20px 0px">
      <div style="text-align: left">
        <span style="font-size: 18px; font-weight: 700; line-height: 49px"
          >产品要素</span
        >
      </div>
      <el-descriptions direction="horizontal" :column="4" border>
        <el-descriptions-item :span="4" label="产品名称">{{
          chartData.product_name
        }}</el-descriptions-item>
        <el-descriptions-item :span="2" label="管理人">{{
          chartData.company
        }}</el-descriptions-item>
        <el-descriptions-item :span="2" label="托管人">{{
          chartData.trustee
        }}</el-descriptions-item>

        <el-descriptions-item :span="2" label="投资经理">
          {{ chartData.manager }}
        </el-descriptions-item>

        <el-descriptions-item
          :span="2"
          label="风险等级
"
        >
          {{ chartData.risk_rank }}-
          <span v-if="chartData.risk_rank == 'R1'">低风险</span>
          <span v-if="chartData.risk_rank == 'R2'">中低风险</span>
          <span v-if="chartData.risk_rank == 'R3'">中风险</span>
          <span v-if="chartData.risk_rank == 'R4'">中高风险</span>
          <span v-if="chartData.risk_rank == 'R5'">高风险</span>
        </el-descriptions-item>

        <el-descriptions-item :span="2" label="成立日期">
          {{ chartData.established }}</el-descriptions-item
        >
        <el-descriptions-item :span="2" label="公司详情">
          {{ chartData.company }}</el-descriptions-item
        >

        <el-descriptions-item :span="2" label="备案编码">
          {{ chartData.record_id }}</el-descriptions-item
        >

        <el-descriptions-item :span="2" label="产品存续期">
          {{ chartData.product_lifetime }}
        </el-descriptions-item>

        <el-descriptions-item :span="2" label="产品封闭期">{{
          chartData.closure_period
        }}</el-descriptions-item>

        <el-descriptions-item :span="4" label="锁定期">
          {{ chartData.lockup_period }}</el-descriptions-item
        >
        <el-descriptions-item :span="4" label="预警线/止损线">
          预警线：{{ chartData.warning_line }}　止损线：{{
            chartData.stop_line
          }}
          本基金设置的预警线和止损线较低，投资者可能会存在较大的本金亏损风险，请注意投资风险。</el-descriptions-item
        >
        <el-descriptions-item :span="4" label="申购开放日">
           <span v-html="chartData.subscription_date"> </span>
        </el-descriptions-item>
        <el-descriptions-item :span="4" label="赎回开放日">
      
          <span v-html="chartData.redemption_date"> </span>
          
          </el-descriptions-item
        >
        <el-descriptions-item :span="4" label="投资范围">
          {{ chartData.investment_scope }}</el-descriptions-item
        >
        <el-descriptions-item :span="4" label="投资策略">
          {{ chartData.strategy }}</el-descriptions-item
        >
        <el-descriptions-item :span="4" label="投资限制">
          {{ chartData.investment_limits }}</el-descriptions-item
        >
        <el-descriptions-item :span="4" label="信息披露周期">
          以基金合同的约定及相关法律法规的规定为准。</el-descriptions-item
        >
        <el-descriptions-item :span="4" label="分红方式">
          分红再投资；不支持修改分红分配方式，以管理人实际分配方案为准</el-descriptions-item
        >
      </el-descriptions>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    chartData: {
      type: Object,
      default() {
        return {};
      },
    },

    fund_data: {
      type: Object,
      default: "",
    },
  },
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>
<style >
</style> 